import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { Radio, Button } from '@material-ui/core';
import styles from '../CustomersList.scss';

const CustomersListFiltersRegularBase = ({
  nameEmailInput,
  setPageState,
  setNameEmailInput,
  clientPageEventLogger,
  clearFiltersClicked,
  allCLientsState,
  liveToggle,
  setLiveToggle,
  archiveToggle,
  setArchiveToggle,
  nfFlag,
  filtersConfig,
  doNotHaveUpcomingAssessmentToggle,
  setDoNotHaveUpcomingAssessmentToggle,
  completedAssessmentsMin,
  completedAssessmentsMax,
  setCompletedAssessmentsMin,
  setCompletedAssessmentsMax,
  filterModalState,
  setFilterModalState,
  anchorEl,
  hasRemainingSessionsToggle,
  setHasRemainingSessionsToggle,
  completedSessionMin,
  completedSessionMax,
  setCompletedSessionMin,
  setCompletedSessionMax,
  hasProgressReportToggle,
  setHasProgressReportToggle,
  generatedReportsMin,
  setGeneratedReportsMin,
  generatedReportsMax,
  setGeneratedReportsMax,
  journeyEnableToggle,
  setJourneyEnableToggle,
  completedJourneyMin,
  setCompletedJourneyMin,
  completedJourneyMax,
  setCompletedJourneyMax,
  actionNeededToggle,
  setActionNeededToggle,
  pendingJournalsToggle,
  setPendingJournalsToggle,
  setAllCLientsState,
  onFilterChange,
  setArchiveCheckboxes,
  filterModalDisplayClicked,
  clearFiltersRerender,
  setClearFiltersRerender,
  shouldShowTeamMemberAssign,
  setAssignedToggle,
  assignedToggle,
}) => {
  return (
    <div className={styles.filters_container}>
      <div className={styles.filters_container_row}>
        <input
          className={styles.name_input}
          placeholder="🔍 Client name/email"
          value={nameEmailInput}
          onChange={e => {
            setPageState(0);
            setNameEmailInput(e.target.value);
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'search_box_modified',
              search_box_value: e.target.value,
              team_member_id: sessionStorage.teamMemberId,
            });
          }}
          onFocus={() => {
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'search_box',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value: nameEmailInput,
            });
          }}
        />
        {!nfFlag ? null : (
          <div className={styles.filter_btn_container}>
            <div
              className={styles.filter_btn}
              style={
                // eslint-disable-next-line no-nested-ternary
                filtersConfig.hasRemainingSessionsToggle === true ||
                filtersConfig.hasRemainingSessionsToggle === false ||
                filtersConfig.completedSessionMin ||
                filtersConfig.completedSessionMax
                  ? //    || noSessionsLeftToggle
                    { color: 'white', backgroundColor: '#2196f3' }
                  : filterModalState === 'training-status'
                  ? { borderColor: '#96c0f5' }
                  : null
              }
              onClick={e => {
                filterModalDisplayClicked('training-status', e);
                setPageState(0);
                clientPageEventLogger.log('item_clicked', {
                  button_clicked: 'filters',
                  filters_type: 'training_status',
                  search_box_value: nameEmailInput,
                  team_member_id: sessionStorage.teamMemberId,
                });
              }}
            >
              Training status
              {filtersConfig.hasRemainingSessionsToggle === true ||
              filtersConfig.hasRemainingSessionsToggle === false ||
              filtersConfig.completedSessionMin ||
              filtersConfig.completedSessionMax ? (
                <CloseIcon
                  style={{
                    width: 15,
                    height: 15,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    clientPageEventLogger.log('filter_cleared', {
                      filter_type: 'training_status',
                      sub_filter_1_name: 'has_remaining_sessions',
                      sub_filter_1_value: hasRemainingSessionsToggle,
                      sub_filter_2_name: 'completed_sessions',
                      sub_filter_2_value: [
                        completedSessionMin,
                        completedSessionMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                    setHasRemainingSessionsToggle(null);
                    setCompletedSessionMin('');
                    setCompletedSessionMax('');
                    // onFilterChange();
                    setClearFiltersRerender(!clearFiltersRerender);
                  }}
                />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
            <Popover
              id="training-status"
              open={filterModalState === 'training-status'}
              anchorEl={anchorEl}
              onClose={() => {
                setFilterModalState('');
                setHasRemainingSessionsToggle(
                  filtersConfig.hasRemainingSessionsToggle
                );
                setCompletedSessionMin(filtersConfig.completedSessionMin);
                setCompletedSessionMax(filtersConfig.completedSessionMax);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              classes={{ paper: styles.popover_paper }}
            >
              <span>
                <div className={styles.filter_modal_item_title}>
                  {/* Training stauts */}
                  <CloseIcon
                    onClick={() => {
                      setFilterModalState('');
                      setHasRemainingSessionsToggle(
                        filtersConfig.hasRemainingSessionsToggle
                      );
                      setCompletedSessionMin(filtersConfig.completedSessionMin);
                      setCompletedSessionMax(filtersConfig.completedSessionMax);
                    }}
                    style={{
                      width: 15,
                      height: 15,
                      color: '#9F9F9F',
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <div className={styles.filter_modal_item}>
                  <div>Has remaining sessions</div>
                  <div>
                    <Radio
                      checked={hasRemainingSessionsToggle === true}
                      onChange={() => setHasRemainingSessionsToggle(true)}
                      color="primary"
                    />{' '}
                    <span className={styles.radio_label}>Yes</span>
                    <Radio
                      checked={hasRemainingSessionsToggle === false}
                      onChange={() => setHasRemainingSessionsToggle(false)}
                      color="primary"
                    />{' '}
                    <span className={styles.radio_label}>No</span>
                  </div>
                </div>
                <div className={styles.filter_modal_item}>
                  <div style={{ marginRight: 10 }}>Completed sessions</div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 10 }}>
                      <div className={styles.filter_input_label}>Min</div>
                      <input
                        type="number"
                        className={styles.filter_input_number}
                        value={completedSessionMin}
                        onChange={e => setCompletedSessionMin(e.target.value)}
                        min={0}
                      />
                    </div>
                    <div>
                      <div className={styles.filter_input_label}>Max</div>
                      <input
                        type="number"
                        className={styles.filter_input_number}
                        value={completedSessionMax}
                        onChange={e => setCompletedSessionMax(e.target.value)}
                        min={0}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.filter_popup_buttons}>
                  <Button
                    // color="primary"
                    onClick={() => {
                      clientPageEventLogger.log('filter_cleared', {
                        filter_type: 'training_status',
                        sub_filter_1_name: 'has_remaining_sessions',
                        sub_filter_1_value: hasRemainingSessionsToggle,
                        sub_filter_2_name: 'completed_sessions',
                        sub_filter_2_value: [
                          completedSessionMin,
                          completedSessionMax,
                        ],
                        team_member_id: sessionStorage.teamMemberId,
                      });
                      setHasRemainingSessionsToggle(null);
                      setCompletedSessionMin('');
                      setCompletedSessionMax('');
                      //   onFilterChange();
                      setClearFiltersRerender(!clearFiltersRerender);
                    }}
                    style={{ marginRight: 5, fontSize: 12 }}
                  >
                    Clear all
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      onFilterChange();
                      clientPageEventLogger.log('filter_applied', {
                        filter_type: 'training_status',
                        sub_filter_1_name: 'has_remaining_sessions',
                        sub_filter_1_value: hasRemainingSessionsToggle,
                        sub_filter_2_name: 'completed_sessions',
                        sub_filter_2_value: [
                          completedSessionMin,
                          completedSessionMax,
                        ],
                        team_member_id: sessionStorage.teamMemberId,
                      });
                    }}
                    style={{ fontSize: 12 }}
                  >
                    Save
                  </Button>
                </div>
              </span>
            </Popover>
          </div>
        )}
        <div className={styles.filter_btn_container}>
          <div
            className={styles.filter_btn}
            style={
              // eslint-disable-next-line no-nested-ternary
              filtersConfig.doNotHaveUpcomingAssessmentToggle === true ||
              filtersConfig.doNotHaveUpcomingAssessmentToggle === false ||
              filtersConfig.completedAssessmentsMin ||
              filtersConfig.completedAssessmentsMax
                ? { color: 'white', backgroundColor: '#2196f3' }
                : filterModalState === 'assessment-status'
                ? { borderColor: '#96c0f5' }
                : null
            }
            onClick={e => {
              filterModalDisplayClicked('assessment-status', e);
              setPageState(0);
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'filters',
                filters_type: 'assessment',
                search_box_value: nameEmailInput,
                team_member_id: sessionStorage.teamMemberId,
              });
            }}
          >
            Assessment
            {filtersConfig.doNotHaveUpcomingAssessmentToggle === true ||
            filtersConfig.doNotHaveUpcomingAssessmentToggle === false ||
            filtersConfig.completedAssessmentsMin ||
            filtersConfig.completedAssessmentsMax ? (
              <CloseIcon
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation();
                  clientPageEventLogger.log('filter_cleared', {
                    filter_type: 'assessment',
                    sub_filter_1_name: 'do_not_have_upcoming_assessment',
                    sub_filter_1_value: doNotHaveUpcomingAssessmentToggle,
                    sub_filter_2_name: 'completed_assessments',
                    sub_filter_2_value: [
                      completedAssessmentsMin,
                      completedAssessmentsMax,
                    ],
                    team_member_id: sessionStorage.teamMemberId,
                  });
                  setDoNotHaveUpcomingAssessmentToggle(null);
                  setCompletedAssessmentsMin('');
                  setCompletedAssessmentsMax('');
                  // onFilterChange();
                  setClearFiltersRerender(!clearFiltersRerender);
                }}
              />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          <Popover
            id="assessment-status"
            open={filterModalState === 'assessment-status'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setDoNotHaveUpcomingAssessmentToggle(
                filtersConfig.doNotHaveUpcomingAssessmentToggle
              );
              setCompletedAssessmentsMin(filtersConfig.completedAssessmentsMin);
              setCompletedAssessmentsMax(filtersConfig.completedAssessmentsMax);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <span>
              <div className={styles.filter_modal_item_title}>
                {/* Assessment */}
                <CloseIcon
                  onClick={() => {
                    setFilterModalState('');
                    setDoNotHaveUpcomingAssessmentToggle(
                      filtersConfig.doNotHaveUpcomingAssessmentToggle
                    );
                    setCompletedAssessmentsMin(
                      filtersConfig.completedAssessmentsMin
                    );
                    setCompletedAssessmentsMax(
                      filtersConfig.completedAssessmentsMax
                    );
                  }}
                  style={{
                    width: 15,
                    height: 15,
                    color: '#9F9F9F',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className={styles.filter_modal_item}>
                <div>Upcoming assessment</div>
                <div>
                  <Radio
                    checked={doNotHaveUpcomingAssessmentToggle === true}
                    onChange={() => setDoNotHaveUpcomingAssessmentToggle(true)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>Yes</span>
                  <Radio
                    checked={doNotHaveUpcomingAssessmentToggle === false}
                    onChange={() => setDoNotHaveUpcomingAssessmentToggle(false)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>No</span>
                </div>
              </div>
              <div className={styles.filter_modal_item}>
                <div style={{ marginRight: 10 }}>Completed assessments</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 10 }}>
                    <div className={styles.filter_input_label}>Min</div>
                    <input
                      type="number"
                      className={styles.filter_input_number}
                      value={completedAssessmentsMin}
                      onChange={e => setCompletedAssessmentsMin(e.target.value)}
                      min={0}
                    />
                  </div>
                  <div>
                    <div className={styles.filter_input_label}>Max</div>
                    <input
                      type="number"
                      className={styles.filter_input_number}
                      value={completedAssessmentsMax}
                      onChange={e => setCompletedAssessmentsMax(e.target.value)}
                      min={0}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.filter_popup_buttons}>
                <Button
                  // color="primary"
                  onClick={() => {
                    clientPageEventLogger.log('filter_cleared', {
                      filter_type: 'assessment',
                      sub_filter_1_name: 'do_not_have_upcoming_assessment',
                      sub_filter_1_value: doNotHaveUpcomingAssessmentToggle,
                      sub_filter_2_name: 'completed_assessments',
                      sub_filter_2_value: [
                        completedAssessmentsMin,
                        completedAssessmentsMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                    setDoNotHaveUpcomingAssessmentToggle(null);
                    setCompletedAssessmentsMin('');
                    setCompletedAssessmentsMax('');
                    //   onFilterChange();
                    setClearFiltersRerender(!clearFiltersRerender);
                  }}
                  style={{ marginRight: 5, fontSize: 12 }}
                >
                  Clear all
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onFilterChange();
                    clientPageEventLogger.log('filter_applied', {
                      filter_type: 'assessment',
                      sub_filter_1_name: 'do_not_have_upcoming_assessment',
                      sub_filter_1_value: doNotHaveUpcomingAssessmentToggle,
                      sub_filter_2_name: 'completed_assessments',
                      sub_filter_2_value: [
                        completedAssessmentsMin,
                        completedAssessmentsMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                  }}
                  style={{ fontSize: 12 }}
                >
                  Save
                </Button>
              </div>
            </span>
          </Popover>
        </div>
        <div className={styles.filter_btn_container}>
          <div
            className={styles.filter_btn}
            style={
              // eslint-disable-next-line no-nested-ternary
              filtersConfig.hasProgressReportToggle === true ||
              filtersConfig.hasProgressReportToggle === false ||
              filtersConfig.generatedReportsMin ||
              filtersConfig.generatedReportsMax
                ? { color: 'white', backgroundColor: '#2196f3' }
                : filterModalState === 'report-status'
                ? { borderColor: '#96c0f5' }
                : null
            }
            onClick={e => {
              filterModalDisplayClicked('report-status', e);
              setPageState(0);
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'filters',
                filters_type: 'reporting',
                search_box_value: nameEmailInput,
                team_member_id: sessionStorage.teamMemberId,
              });
            }}
          >
            Reporting
            {filtersConfig.hasProgressReportToggle === true ||
            filtersConfig.hasProgressReportToggle === false ||
            filtersConfig.generatedReportsMin ||
            filtersConfig.generatedReportsMax ? (
              <CloseIcon
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation();
                  clientPageEventLogger.log('filter_cleared', {
                    filter_type: 'reporting',
                    sub_filter_1_name: 'has_progress_report',
                    sub_filter_1_value: hasProgressReportToggle,
                    sub_filter_2_name: 'generated_reports',
                    sub_filter_2_value: [
                      generatedReportsMin,
                      generatedReportsMax,
                    ],
                    team_member_id: sessionStorage.teamMemberId,
                  });
                  setHasProgressReportToggle(null);
                  setGeneratedReportsMin('');
                  setGeneratedReportsMax('');
                  // onFilterChange();
                  setClearFiltersRerender(!clearFiltersRerender);
                }}
              />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          <Popover
            id="report-status"
            open={filterModalState === 'report-status'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setHasProgressReportToggle(filtersConfig.hasProgressReportToggle);
              setGeneratedReportsMin(filtersConfig.generatedReportsMin);
              setGeneratedReportsMax(filtersConfig.generatedReportsMax);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <span>
              <div className={styles.filter_modal_item_title}>
                {/* Reporting */}
                <CloseIcon
                  onClick={() => {
                    setFilterModalState('');
                    setHasProgressReportToggle(
                      filtersConfig.hasProgressReportToggle
                    );
                    setGeneratedReportsMin(filtersConfig.generatedReportsMin);
                    setGeneratedReportsMax(filtersConfig.generatedReportsMax);
                  }}
                  style={{
                    width: 15,
                    height: 15,
                    color: '#9F9F9F',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className={styles.filter_modal_item}>
                <div>Has a progress report</div>
                <div>
                  <Radio
                    checked={hasProgressReportToggle === true}
                    onChange={() => setHasProgressReportToggle(true)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>Yes</span>
                  <Radio
                    checked={hasProgressReportToggle === false}
                    onChange={() => setHasProgressReportToggle(false)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>No</span>
                </div>
              </div>
              <div className={styles.filter_modal_item}>
                <div style={{ marginRight: 10 }}>Generated reports</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 10 }}>
                    <div className={styles.filter_input_label}>Min</div>
                    <input
                      type="number"
                      className={styles.filter_input_number}
                      value={generatedReportsMin}
                      onChange={e => setGeneratedReportsMin(e.target.value)}
                    />
                  </div>
                  <div>
                    <div className={styles.filter_input_label}>Max</div>
                    <input
                      type="number"
                      className={styles.filter_input_number}
                      value={generatedReportsMax}
                      onChange={e => setGeneratedReportsMax(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.filter_popup_buttons}>
                <Button
                  // color="primary"
                  onClick={() => {
                    clientPageEventLogger.log('filter_cleared', {
                      filter_type: 'reporting',
                      sub_filter_1_name: 'has_progress_report',
                      sub_filter_1_value: hasProgressReportToggle,
                      sub_filter_2_name: 'generated_reports',
                      sub_filter_2_value: [
                        generatedReportsMin,
                        generatedReportsMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                    setHasProgressReportToggle(null);
                    setGeneratedReportsMin('');
                    setGeneratedReportsMax('');
                    //   onFilterChange();
                    setClearFiltersRerender(!clearFiltersRerender);
                  }}
                  style={{ marginRight: 5, fontSize: 12 }}
                >
                  Clear all
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onFilterChange();
                    clientPageEventLogger.log('filter_applied', {
                      filter_type: 'reporting',
                      sub_filter_1_name: 'has_progress_report',
                      sub_filter_1_value: hasProgressReportToggle,
                      sub_filter_2_name: 'generated_reports',
                      sub_filter_2_value: [
                        generatedReportsMin,
                        generatedReportsMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                  }}
                  style={{ fontSize: 12 }}
                >
                  Save
                </Button>
              </div>
            </span>
          </Popover>
        </div>
        <div className={styles.filter_btn_container}>
          <div
            className={styles.filter_btn}
            style={
              // eslint-disable-next-line no-nested-ternary
              filtersConfig.journeyEnableToggle === true ||
              filtersConfig.journeyEnableToggle === false ||
              filtersConfig.completedJourneyMin ||
              filtersConfig.completedJourneyMax
                ? { color: 'white', backgroundColor: '#2196f3' }
                : filterModalState === 'journey-status'
                ? { borderColor: '#96c0f5' }
                : null
            }
            onClick={e => {
              filterModalDisplayClicked('journey-status', e);
              setPageState(0);
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'filters',
                filters_type: 'journey',
                search_box_value: nameEmailInput,
                team_member_id: sessionStorage.teamMemberId,
              });
            }}
          >
            Journey
            {filtersConfig.journeyEnableToggle === true ||
            filtersConfig.journeyEnableToggle === false ||
            filtersConfig.completedJourneyMin ||
            filtersConfig.completedJourneyMax ? (
              <CloseIcon
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation();
                  clientPageEventLogger.log('filter_cleared', {
                    filter_type: 'journey',
                    sub_filter_1_name: 'is_journey_enabled',
                    sub_filter_1_value: journeyEnableToggle,
                    sub_filter_2_name: 'completed_journey',
                    sub_filter_2_value: [
                      completedJourneyMin,
                      completedJourneyMax,
                    ],
                    team_member_id: sessionStorage.teamMemberId,
                  });
                  setJourneyEnableToggle(null);
                  setCompletedJourneyMin('');
                  setCompletedJourneyMax('');
                  // onFilterChange();
                  setClearFiltersRerender(!clearFiltersRerender);
                }}
              />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          <Popover
            id="journey-status"
            open={filterModalState === 'journey-status'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setJourneyEnableToggle(filtersConfig.journeyEnableToggle);
              setCompletedJourneyMin(filtersConfig.completedJourneyMin);
              setCompletedJourneyMax(filtersConfig.completedJourneyMax);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <span>
              <div className={styles.filter_modal_item_title}>
                {/* Journey */}
                <CloseIcon
                  onClick={() => {
                    setFilterModalState('');
                    setJourneyEnableToggle(filtersConfig.journeyEnableToggle);
                    setCompletedJourneyMin(filtersConfig.completedJourneyMin);
                    setCompletedJourneyMax(filtersConfig.completedJourneyMax);
                  }}
                  style={{
                    width: 15,
                    height: 15,
                    color: '#9F9F9F',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className={styles.filter_modal_item}>
                <div>Has a journey</div>
                <div>
                  <Radio
                    checked={journeyEnableToggle === true}
                    onChange={() => setJourneyEnableToggle(true)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>Yes</span>
                  <Radio
                    checked={journeyEnableToggle === false}
                    onChange={() => setJourneyEnableToggle(false)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>No</span>
                </div>
              </div>
              <div className={styles.filter_modal_item}>
                <div style={{ marginRight: 10 }}>Completion rate</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 10 }}>
                    <div className={styles.filter_input_label}>Min %</div>
                    <input
                      type="number"
                      className={styles.filter_input_number}
                      value={completedJourneyMin}
                      onChange={e => setCompletedJourneyMin(e.target.value)}
                      min={0}
                      max={100}
                    />
                  </div>
                  <div>
                    <div className={styles.filter_input_label}>Max %</div>
                    <input
                      type="number"
                      className={styles.filter_input_number}
                      value={completedJourneyMax}
                      onChange={e => setCompletedJourneyMax(e.target.value)}
                      max={100}
                      min={0}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.filter_popup_buttons}>
                <Button
                  // color="primary"
                  onClick={() => {
                    clientPageEventLogger.log('filter_cleared', {
                      filter_type: 'journey',
                      sub_filter_1_name: 'is_journey_enabled',
                      sub_filter_1_value: journeyEnableToggle,
                      sub_filter_2_name: 'completed_journey',
                      sub_filter_2_value: [
                        completedJourneyMin,
                        completedJourneyMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                    setJourneyEnableToggle(null);
                    setCompletedJourneyMin('');
                    setCompletedJourneyMax('');
                    //   onFilterChange();
                    setClearFiltersRerender(!clearFiltersRerender);
                  }}
                  style={{ marginRight: 5, fontSize: 12 }}
                >
                  Clear all
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onFilterChange();
                    clientPageEventLogger.log('filter_applied', {
                      filter_type: 'journey',
                      sub_filter_1_name: 'is_journey_enabled',
                      sub_filter_1_value: journeyEnableToggle,
                      sub_filter_2_name: 'completed_journey',
                      sub_filter_2_value: [
                        completedJourneyMin,
                        completedJourneyMax,
                      ],
                      team_member_id: sessionStorage.teamMemberId,
                    });
                  }}
                  style={{ fontSize: 12 }}
                >
                  Save
                </Button>
              </div>
            </span>
          </Popover>
        </div>
        <div className={styles.filter_btn_container}>
          <div
            className={styles.filter_btn}
            style={
              // eslint-disable-next-line no-nested-ternary
              filtersConfig.actionNeededToggle === true ||
              filtersConfig.actionNeededToggle === false ||
              filtersConfig.pendingJournalsToggle === true ||
              filtersConfig.pendingJournalsToggle === false
                ? { color: 'white', backgroundColor: '#2196f3' }
                : filterModalState === 'notifications1'
                ? { borderColor: '#96c0f5' }
                : null
            }
            onClick={e => {
              filterModalDisplayClicked('notifications1', e);
              setPageState(0);
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'filters',
                filters_type: 'notifications',
                search_box_value: nameEmailInput,
                team_member_id: sessionStorage.teamMemberId,
              });
            }}
          >
            Notifications
            {filtersConfig.actionNeededToggle === true ||
            filtersConfig.actionNeededToggle === false ||
            filtersConfig.pendingJournalsToggle === true ||
            filtersConfig.pendingJournalsToggle === false ? (
              <CloseIcon
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation();
                  clientPageEventLogger.log('filter_cleared', {
                    filter_type: 'notifications',
                    sub_filter_1_name: 'action_needed',
                    sub_filter_1_value: actionNeededToggle,
                    sub_filter_2_name: 'pending_journals',
                    sub_filter_2_value: pendingJournalsToggle,
                    team_member_id: sessionStorage.teamMemberId,
                  });
                  setActionNeededToggle(null);
                  setPendingJournalsToggle(null);
                  // onFilterChange();
                  setClearFiltersRerender(!clearFiltersRerender);
                }}
              />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          <Popover
            id="notifications1"
            open={filterModalState === 'notifications1'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setActionNeededToggle(filtersConfig.actionNeededToggle);
              setPendingJournalsToggle(filtersConfig.pendingJournalsToggle);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <span>
              <div className={styles.filter_modal_item_title}>
                {/* Notifications */}
                <CloseIcon
                  onClick={() => {
                    setFilterModalState('');
                    setActionNeededToggle(filtersConfig.actionNeededToggle);
                    setPendingJournalsToggle(
                      filtersConfig.pendingJournalsToggle
                    );
                  }}
                  style={{
                    width: 15,
                    height: 15,
                    color: '#9F9F9F',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className={styles.filter_modal_item}>
                <div>Action needed</div>
                <div>
                  <Radio
                    checked={actionNeededToggle === true}
                    onChange={() => setActionNeededToggle(true)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>Yes</span>
                  <Radio
                    checked={actionNeededToggle === false}
                    onChange={() => setActionNeededToggle(false)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>No</span>
                </div>
              </div>
              <div className={styles.filter_modal_item}>
                <div>Pending entries</div>
                <div>
                  <Radio
                    checked={pendingJournalsToggle === true}
                    onChange={() => setPendingJournalsToggle(true)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>Yes</span>
                  <Radio
                    checked={pendingJournalsToggle === false}
                    onChange={() => setPendingJournalsToggle(false)}
                    color="primary"
                  />{' '}
                  <span className={styles.radio_label}>No</span>
                </div>
              </div>
              <div className={styles.filter_popup_buttons}>
                <Button
                  // color="primary"
                  onClick={() => {
                    clientPageEventLogger.log('filter_cleared', {
                      filter_type: 'notifications',
                      sub_filter_1_name: 'action_needed',
                      sub_filter_1_value: actionNeededToggle,
                      sub_filter_2_name: 'pending_journals',
                      sub_filter_2_value: pendingJournalsToggle,
                      team_member_id: sessionStorage.teamMemberId,
                    });
                    setActionNeededToggle(null);
                    setPendingJournalsToggle(null);
                    //   onFilterChange();
                    setClearFiltersRerender(!clearFiltersRerender);
                  }}
                  style={{ marginRight: 5, fontSize: 12 }}
                >
                  Clear all
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onFilterChange();
                    clientPageEventLogger.log('filter_applied', {
                      filter_type: 'notifications',
                      sub_filter_1_name: 'action_needed',
                      sub_filter_1_value: actionNeededToggle,
                      sub_filter_2_name: 'pending_journals',
                      sub_filter_2_value: pendingJournalsToggle,
                      team_member_id: sessionStorage.teamMemberId,
                    });
                  }}
                  style={{ fontSize: 12 }}
                >
                  Save
                </Button>
              </div>
            </span>
          </Popover>
        </div>
      </div>
      <div className={styles.filters_container_row}>
        <div
          className={styles.filter_btn_exclusive_first}
          onClick={() => {
            clearFiltersClicked();
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'clients_disp',
              clients_disp: 'all_clients',
              search_box_value: nameEmailInput,
              team_member_id: sessionStorage.teamMemberId,
              display_enable: allCLientsState,
            });
          }}
          style={
            allCLientsState
              ? { color: 'white', backgroundColor: '#2196f3' }
              : null
          }
        >
          All clients
        </div>
        <div
          className={styles.filter_btn_exclusive}
          style={
            liveToggle ? { color: 'white', backgroundColor: '#2196f3' } : null
          }
          onClick={() => {
            if (!liveToggle) {
              setAllCLientsState(false);
            }
            if (archiveToggle) {
              setArchiveToggle(false);
            }
            if (assignedToggle) {
              setAssignedToggle(false);
            }
            setLiveToggle(!liveToggle);
            onFilterChange();
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'clients_disp',
              clients_disp: 'live',
              search_box_value: nameEmailInput,
              team_member_id: sessionStorage.teamMemberId,
              display_enable: !liveToggle,
            });
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#4BE0D0',
                width: 10,
                height: 10,
                marginRight: 5,
              }}
            />
            Live
          </div>
        </div>
        <div
          className={styles.filter_btn_exclusive}
          onClick={() => {
            if (!archiveToggle) {
              setAllCLientsState(false);
            } else {
              if (
                (filtersConfig.actionNeededToggle === null ||
                  filtersConfig.doNotHaveUpcomingAssessmentToggle ===
                    undefined) &&
                filtersConfig.completedAssessmentsMax === '' &&
                filtersConfig.completedAssessmentsMin === '' &&
                filtersConfig.completedJourneyMax === '' &&
                filtersConfig.completedJourneyMin === '' &&
                filtersConfig.completedSessionMax === '' &&
                filtersConfig.completedSessionMin === '' &&
                (filtersConfig.doNotHaveUpcomingAssessmentToggle === null ||
                  filtersConfig.doNotHaveUpcomingAssessmentToggle ===
                    undefined) &&
                filtersConfig.generatedReportsMax === '' &&
                filtersConfig.generatedReportsMin === '' &&
                (filtersConfig.hasProgressReportToggle === null ||
                  filtersConfig.hasProgressReportToggle === undefined) &&
                (filtersConfig.hasRemainingSessionsToggle === null ||
                  filtersConfig.hasRemainingSessionsToggle === undefined) &&
                (filtersConfig.journeyEnableToggle === null ||
                  filtersConfig.journeyEnableToggle === undefined) &&
                (filtersConfig.pendingJournalsToggle === null ||
                  filtersConfig.pendingJournalsToggle === undefined)
              ) {
                setAllCLientsState(true);
              }
            }
            if (liveToggle) {
              setLiveToggle(false);
            }
            if (assignedToggle) {
              setAssignedToggle(false);
            }
            setArchiveToggle(!archiveToggle);
            setArchiveCheckboxes({});
            onFilterChange();
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'clients_disp',
              clients_disp: 'archive',
              search_box_value: nameEmailInput,
              team_member_id: sessionStorage.teamMemberId,
              display_enable: !archiveToggle,
            });
          }}
          style={
            archiveToggle
              ? { color: 'white', backgroundColor: '#2196f3' }
              : null
          }
        >
          Archived
        </div>
        {shouldShowTeamMemberAssign &&
        sessionStorage.isTeamMember === 'true' ? (
          <div
            className={styles.filter_btn_exclusive}
            style={
              assignedToggle
                ? { color: 'white', backgroundColor: '#2196f3' }
                : null
            }
            onClick={() => {
              if (!assignedToggle) {
                setAllCLientsState(false);
              }
              if (archiveToggle) {
                setArchiveToggle(false);
              }
              if (liveToggle) {
                setLiveToggle(false);
              }
              setAssignedToggle(!assignedToggle);
              onFilterChange();
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'clients_disp',
                clients_disp: 'assign',
                search_box_value: nameEmailInput,
                team_member_id: sessionStorage.teamMemberId,
                display_enable: !assignedToggle,
              });
            }}
          >
            Assigned to me
          </div>
        ) : null}
      </div>
    </div>
  );
};

CustomersListFiltersRegularBase.propTypes = exact({
  nameEmailInput: PropTypes.string,
  setPageState: PropTypes.func,
  setNameEmailInput: PropTypes.func,
  clientPageEventLogger: PropTypes.object,
  clearFiltersClicked: PropTypes.func,
  allCLientsState: PropTypes.bool,
  liveToggle: PropTypes.bool,
  setLiveToggle: PropTypes.func,
  archiveToggle: PropTypes.bool,
  setArchiveToggle: PropTypes.func,
  nfFlag: PropTypes.bool,
  filtersConfig: PropTypes.object,
  doNotHaveUpcomingAssessmentToggle: PropTypes.object,
  setDoNotHaveUpcomingAssessmentToggle: PropTypes.func,
  completedAssessmentsMin: PropTypes.string,
  completedAssessmentsMax: PropTypes.string,
  setCompletedAssessmentsMin: PropTypes.func,
  setCompletedAssessmentsMax: PropTypes.func,
  filterModalState: PropTypes.string,
  setFilterModalState: PropTypes.func,
  anchorEl: PropTypes.object,
  hasRemainingSessionsToggle: PropTypes.object,
  setHasRemainingSessionsToggle: PropTypes.func,
  completedSessionMin: PropTypes.string,
  completedSessionMax: PropTypes.string,
  setCompletedSessionMin: PropTypes.func,
  setCompletedSessionMax: PropTypes.func,
  hasProgressReportToggle: PropTypes.object,
  setHasProgressReportToggle: PropTypes.func,
  generatedReportsMin: PropTypes.string,
  setGeneratedReportsMin: PropTypes.func,
  generatedReportsMax: PropTypes.string,
  setGeneratedReportsMax: PropTypes.func,
  journeyEnableToggle: PropTypes.object,
  setJourneyEnableToggle: PropTypes.func,
  completedJourneyMin: PropTypes.string,
  setCompletedJourneyMin: PropTypes.func,
  completedJourneyMax: PropTypes.string,
  setCompletedJourneyMax: PropTypes.func,
  actionNeededToggle: PropTypes.object,
  setActionNeededToggle: PropTypes.func,
  pendingJournalsToggle: PropTypes.object,
  setPendingJournalsToggle: PropTypes.func,
  setAllCLientsState: PropTypes.func,
  onFilterChange: PropTypes.func,
  setArchiveCheckboxes: PropTypes.func,
  filterModalDisplayClicked: PropTypes.func,
  clearFiltersRerender: PropTypes.bool,
  setClearFiltersRerender: PropTypes.func,
  shouldShowTeamMemberAssign: PropTypes.bool,
  setAssignedToggle: PropTypes.func,
  assignedToggle: PropTypes.bool,
});

export const CustomersListFiltersRegular = React.memo(
  CustomersListFiltersRegularBase
);
CustomersListFiltersRegular.displayName = 'CustomersListFiltersRegular';
