import dayJS from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import mapValues from 'lodash/mapValues';
import transform from 'lodash/transform';
import has from 'lodash/has';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import { getSwingleCheckScoreAndColor } from '../swingle-checks/swingle-checks';
import { downloadURIContent, throwForRequiredParam } from '../../utils/utils';
import {
  getCustomerFullName,
  getCustomerInfo,
  getDateNameSuffix,
} from '../customer/customer';

export const aggregateSymptomsTrackingAnswersDataSortedByTimestamp = ({
  symptomsTrackingAnswers,
  allSymptomTrackerQuestions,
  questionsFilter = () => true,
}) => {
  const symptomsTrackingGraphData = [];
  Object.values(symptomsTrackingAnswers)
    .sort((answers1, answers2) => answers1.timestamp - answers2.timestamp)
    .forEach(answers => {
      const data = {};
      data.date = dayJS(+answers.timestamp).format(
        DAY_JS_DATE_FORMATS.namedMonthAndDay
      );

      data.timestamp = answers.timestamp;
      data.questions = [];
      Object.entries(answers.questions).forEach(([questionKey, question]) => {
        if (questionsFilter(allSymptomTrackerQuestions[questionKey])) {
          data.questions.push({
            rating: question.rating,
            name: allSymptomTrackerQuestions[questionKey].translations.en,
          });
        }
      });

      symptomsTrackingGraphData.push(data);
    });

  return symptomsTrackingGraphData;
};

export const turnAggregatedSymptomsTrackingAnswersDataIntoLegendItems = ({
  aggregatedSymptomsTrackingAnswersData,
}) => {
  const legendItemsObject = {};
  aggregatedSymptomsTrackingAnswersData.forEach(symptomsTrackingData => {
    symptomsTrackingData.questions.forEach(question => {
      legendItemsObject[question.name] = true;
    });
  });

  return Object.keys(legendItemsObject).reduce(
    (acc, legendItem) => [...acc, [legendItem, true]],
    []
  );
};

export const getQuestionnaireScoreAndColor = ({
  questionnaire,
  questionnaireResult,
}) => {
  return getSwingleCheckScoreAndColor({
    check: questionnaire,
    result: questionnaireResult,
  });
};

export const getQuestionnaireScoreColor = ({
  questionnaire,
  questionnaireResult,
}) => {
  const [, color] = getSwingleCheckScoreAndColor({
    check: questionnaire,
    result: questionnaireResult,
  });
  return color;
};

export const getQuestionnaireScore = ({
  questionnaire,
  questionnaireResult,
}) => {
  const [score] = getSwingleCheckScoreAndColor({
    check: questionnaire,
    result: questionnaireResult,
  });
  return score;
};

export const getIsQuestionnairePassing = ({
  questionnaire,
  questionnaireResult,
}) => {
  return getQuestionnaireScore({ questionnaire, questionnaireResult }) === 1;
};

export const legacyQuestionsQuestionnaireId = 'legacyQuestionsQuestionnaireId';

export const getSymptomTrackerOldQuestions = async () => {
  const allQuestions = await database
    .ref('symptomTracker/allQuestions')
    .once('value')
    .then(snapshot => snapshot.val());
  return allQuestions;
};

export const getAllQuestionnaires = async (
  { clinicId },
  shouldTakeAllClinics = true
) => {
  const standardQuestionnaires = await database
    .ref('symptomTracker/questionnaires')
    .orderByChild('isStandardized')
    .equalTo(true)
    .once('value')
    .then(snapshot => snapshot.val());
  const defaultQuestionnaires = await database
    .ref('symptomTracker/questionnaires')
    .orderByChild('hideResult')
    .equalTo(true)
    .once('value')
    .then(snapshot => snapshot.val());
  const clinicQuestionnaires = shouldTakeAllClinics
    ? await database
        .ref('symptomTracker/questionnaires')
        .orderByChild('clinicId')
        // .equalTo(clinicId)
        .once('value')
        .then(snapshot => snapshot.val())
    : await database
        .ref('symptomTracker/questionnaires')
        .orderByChild('clinicId')
        .equalTo(clinicId)
        .once('value')
        .then(snapshot => snapshot.val());
  const oldQuestions = await getSymptomTrackerOldQuestions();

  return {
    ...standardQuestionnaires,
    ...defaultQuestionnaires,
    ...clinicQuestionnaires,
    [legacyQuestionsQuestionnaireId]: {
      name: 'Old data',
      questions: Object.entries(oldQuestions).map(([id, question]) => ({
        ...question,
        questionId: id,
        title: question.translations,
      })),
    },
  };
};

export const getAnswersCompatibleWithNewSymptomTracking = ({
  symptomsTrackingAnswers,
}) => {
  return mapValues(symptomsTrackingAnswers, answer => {
    return {
      ...answer,
      questions: transform(answer.questions, (acc, question, questionId) => {
        if (has(question, 'questionnaireId')) {
          acc[questionId] = question;
        } else {
          acc[questionId] = {
            ...question,
            questionnaireId: legacyQuestionsQuestionnaireId,
          };
        }
      }),
    };
  });
};

export const getQuestionsFromSymptomTrackingAnswers = ({
  answersThatHaveQuestionnaireIds,
  allQuestions = throwForRequiredParam('allQuestions'),
}) => {
  const allQuestionsDuplicated = Object.values(
    answersThatHaveQuestionnaireIds
  ).reduce((acc, answer) => {
    return acc.concat(
      Object.keys(answer.questions).map(questionId => allQuestions[questionId])
    );
  }, []);

  return uniqBy(allQuestionsDuplicated, 'questionId');
};

export const getQuestionnaireIdFromQuestion = ({ question }) => {
  const id = question.questionnaireId;
  if (question.subScale) {
    return `${id}-${question.subScale}`;
  }
  return id;
};

export const getQuestionnaireFromQuestion = ({
  question,
  allQuestionnaires,
  shouldDifferentiateBasedOnSubScale,
}) => {
  const id = question.questionnaireId;
  const questionnaire = allQuestionnaires[id];
  const { name } = questionnaire;
  if (shouldDifferentiateBasedOnSubScale && question.subScale) {
    return {
      id: getQuestionnaireIdFromQuestion({ question }),
      originalIdWithoutSubScale: id,
      originalNameWithoutSubScale: name,
      ...questionnaire,
      name: `${name} - ${question.subScale}`,
    };
  }
  return {
    id,
    originalIdWithoutSubScale: id,
    originalNameWithoutSubScale: name,
    ...questionnaire,
  };
};

export const getQuestionnairesFromQuestions = async ({
  questions,
  clinicId,
  shouldDifferentiateBasedOnSubScale = true,
  shouldExcludeCustomQuestionnaires = false,
}) => {
  const allQuestionnaires = await getAllQuestionnaires({ clinicId });

  return questions.reduce((acc, question) => {
    const questionnaire = getQuestionnaireFromQuestion({
      question,
      allQuestionnaires,
      shouldDifferentiateBasedOnSubScale,
    });

    const { id } = questionnaire;
    if (shouldExcludeCustomQuestionnaires ? !questionnaire.clinicId : true) {
      acc[id] = {
        ...questionnaire,
      };
    }
    return acc;
  }, {});
};

export const getQuestionnairesQuestions = ({ questionnaires = {} }) => {
  return Object.entries(questionnaires).reduce(
    (acc, [questionnaireId, questionnaire]) => {
      questionnaire.questions.forEach(question => {
        acc[question.questionId] = {
          ...question,
          questionnaireId,
          translations: question.title,
        };
      });
      return acc;
    },
    {}
  );
};

export const getQuestionnaireLabel = (
  questionnaire,
  { shouldUseSubScale = true } = {}
) => {
  if (shouldUseSubScale) {
    return questionnaire.name;
  }

  return questionnaire.originalNameWithoutSubScale;
};

export const getQuestionnairesScorePercentage = ({
  questionnaireResults,
  questionnaires,
}) => {
  const standardizedQuestionnairesKeys = Object.keys(
    questionnaireResults.standardizedTotalScores || {}
  ).filter(questionnaireId => questionnaires[questionnaireId]);

  const totalScore = standardizedQuestionnairesKeys.reduce(
    (acc, questionnaireId) => {
      return (
        acc +
        getQuestionnaireScore({
          questionnaire: questionnaires[questionnaireId],
          questionnaireResult:
            questionnaireResults.standardizedTotalScores[questionnaireId],
        })
      );
    },
    0
  );

  const totalScorePercentage = Math.floor(
    (totalScore / standardizedQuestionnairesKeys.length) * 100
  );

  return totalScorePercentage;
};

export const isAnEEGRegionStage = (stage, eegIds) =>
  Object.values(eegIds).includes(stage);

export const answersScalesTypes = {
  numbers: 'numbers',
  text: 'text',
};

const getSymptomTrackerCSVData = ({
  questionsFilter = () => true,
  symptomTrackerAnswers,
  allQuestionnaires,
  answerScales,
}) => {
  const CSVColumns = [];
  CSVColumns.push('Category');
  CSVColumns.push('Question');

  // eslint-disable-next-line no-restricted-syntax
  for (const answer of symptomTrackerAnswers) {
    const sessionNumber = `S${answer.sessionNumber}`;

    CSVColumns.push(`${sessionNumber} | ${answer.representableDate}`);
  }

  const CSVData = [CSVColumns];

  const questions = symptomTrackerAnswers.reduce((acc, answer) => {
    Object.keys(answer.questions).forEach(key => {
      acc[key] = answer.questions[key];
    });
    return acc;
  }, {});

  const filteredQuestions = Object.keys(questions).reduce((acc, questionId) => {
    if (questionsFilter(questionId, questions[questionId])) {
      acc[questionId] = questions[questionId];
    }
    return acc;
  }, {});

  // eslint-disable-next-line no-restricted-syntax
  for (const [questionId, question] of Object.entries(filteredQuestions)) {
    const row = [question.symptomRep, question.questionRep];
    // eslint-disable-next-line no-restricted-syntax
    for (const answer of symptomTrackerAnswers) {
      try {
        const answerData = answer.questions[questionId];
        const rating = answerData ? answerData.rating : '';
        let answerText = '';
        if (answerData.questionnaireId) {
          const questionnaire = allQuestionnaires[answerData.questionnaireId];
          const questionFromQuestionnaire =
            questionnaire.questionsById[questionId];
          const { answerScaleId } = questionFromQuestionnaire;
          const answerScale = answerScales[answerScaleId];
          answerText = answerScale.values[rating] || '';
        }
        // eslint-disable-next-line no-unused-expressions
        answerText
          ? row.push(`${rating} | ${answerText}`)
          : row.push(`${rating}`);
      } catch (err) {
        Sentry.captureException(err);
        row.push(' ');
      }
    }

    CSVData.push(row);
  }

  return CSVData;
};

const getOverallSymptomTrackerCSVData = ({
  symptomTrackerAnswers,
  allQuestionnaires,
}) => {
  const CSVColumns = [];
  CSVColumns.push('Questionnaire');

  // eslint-disable-next-line no-restricted-syntax
  for (const answer of symptomTrackerAnswers) {
    const sessionNumber = `S${answer.sessionNumber}`;

    CSVColumns.push(`${sessionNumber} | ${answer.representableDate}`);
  }

  const CSVData = [CSVColumns];
  const questionnairesToDisplay = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const answer of symptomTrackerAnswers) {
    // eslint-disable-next-line no-restricted-syntax
    for (const score in answer.totalScores) {
      if (score) {
        questionnairesToDisplay.push(score);
      }
    }
  }
  const questionnairesToDisplaySet = [
    ...new Set(questionnairesToDisplay),
  ].filter(id => !allQuestionnaires[id].hideResult);
  questionnairesToDisplaySet.forEach(questionnaireId => {
    const row = [allQuestionnaires[questionnaireId].fullName];
    // eslint-disable-next-line no-restricted-syntax
    for (const answer of symptomTrackerAnswers) {
      if (
        answer &&
        answer.totalScores &&
        (answer.totalScores[questionnaireId] ||
          answer.totalScores[questionnaireId] === 0)
      ) {
        row.push(answer.totalScores[questionnaireId]);
      } else {
        row.push('');
      }
    }
    CSVData.push(row);
  });

  return CSVData;
};

export const getOnDownloadSymptomsTrackingDataAsCSV = async ({
  customerId,
}) => {
  try {
    const customerInfo = await getCustomerInfo(customerId);
    // eslint-disable-next-line no-undef
    const patientSymptomTrackerAnswers = new PatientSymptomTrackerAnswers(
      customerId
    );
    const symptomTrackerAnswers = await patientSymptomTrackerAnswers.loadAllRepVersion();

    const allQuestionnaires = await getAllQuestionnaires({
      clinicId: sessionStorage.userId,
    });
    Object.values(allQuestionnaires).forEach(questionnaire => {
      // eslint-disable-next-line no-param-reassign
      questionnaire.questionsById = {};
      questionnaire.questions.forEach(question => {
        const { questionId } = question;
        // eslint-disable-next-line no-param-reassign
        questionnaire.questionsById[questionId] = question;
      });
    });

    const answerScales = await database
      .ref('symptomTracker/answerScales')
      .once('value')
      .then(snapshot => snapshot.val());

    return (questionsFilter = () => true) => {
      const nameHyphensSeparated = getCustomerFullName({
        customerInfo,
        separator: '-',
      });
      const fileName = `individual-symptom-tracking_${getDateNameSuffix({
        nameHyphensSeparated,
      })}`;

      const csvArray = getSymptomTrackerCSVData({
        questionsFilter,
        symptomTrackerAnswers,
        allQuestionnaires,
        answerScales,
      });

      const csv = convertArrayToCSV(csvArray.slice(1), {
        header: csvArray[0],
      });

      return downloadURIContent({
        content: `data:text/csv;charset=utf-8,${csv}`,
        name: `${fileName}.csv`,
      });
    };
  } catch (err) {
    return () => {
      Sentry.captureException(err);
      showNotification('danger', 'There was an error processing csv data');
    };
  }
};

export const getOnDownloadOverallSymptomsTrackingDataAsCSV = async ({
  customerId,
}) => {
  try {
    const customerInfo = await getCustomerInfo(customerId);
    // eslint-disable-next-line no-undef
    const patientSymptomTrackerAnswers = new PatientSymptomTrackerAnswers(
      customerId
    );
    const symptomTrackerAnswers = await patientSymptomTrackerAnswers.loadAllRepVersion();

    const allQuestionnaires = await getAllQuestionnaires({
      clinicId: sessionStorage.userId,
    });
    Object.values(allQuestionnaires).forEach(questionnaire => {
      // eslint-disable-next-line no-param-reassign
      questionnaire.questionsById = {};
      questionnaire.questions.forEach(question => {
        const { questionId } = question;
        // eslint-disable-next-line no-param-reassign
        questionnaire.questionsById[questionId] = question;
      });
    });

    const answerScales = await database
      .ref('symptomTracker/answerScales')
      .once('value')
      .then(snapshot => snapshot.val());

    return (questionsFilter = () => true) => {
      const nameHyphensSeparated = getCustomerFullName({
        customerInfo,
        separator: '-',
      });
      const fileName = `overall-symptom-tracking_${getDateNameSuffix({
        nameHyphensSeparated,
      })}`;

      const csvArray = getOverallSymptomTrackerCSVData({
        questionsFilter,
        symptomTrackerAnswers,
        allQuestionnaires,
        answerScales,
      });

      const csv = convertArrayToCSV(csvArray.slice(1), {
        header: csvArray[0],
      });

      return downloadURIContent({
        content: `data:text/csv;charset=utf-8,${csv}`,
        name: `${fileName}.csv`,
      });
    };
  } catch (err) {
    return () => {
      Sentry.captureException(err);
      showNotification('danger', 'There was an error processing csv data');
    };
  }
};
