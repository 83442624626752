import React, { useEffect, useState, useRef, useCallback } from 'react';
import exact from 'prop-types-exact';
import Swal from 'sweetalert2';
import { convertArrayToCSV } from 'convert-array-to-csv';
import {
  CircularProgress,
  Dialog,
  //   DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  // Radio,
  Menu,
  MenuItem,
  Switch,
  withStyles,
} from '@material-ui/core';
// import Popover from '@material-ui/core/Popover';
// import PersonIcon from '@material-ui/icons/Person';
// import CloseIcon from '@material-ui/icons/Close';
import SwapIcon from '@material-ui/icons/SwapVert';
import MoreIcon from '@material-ui/icons/MoreHoriz';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import DateRangeIcon from '@material-ui/icons/DateRange';
// import InfoIcon from '@material-ui/icons/Info';
import { throttle } from 'lodash';
// import BlueMailIcon from './mail.png';
// import RedMailIcon from './red-mail-icon.png';
// import YellowMailIcon from './yellow-mail-icon.png';
// import FlagIcon from './flag.png';
// import InfoNIcon from './info.png';
import styles from './CustomersList.scss';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { CustomerCard } from './CustomerCard/CustomerCard';
import { AddClientModal } from '../AddClientModal/AddClientModal';
import WarningImg from './warning.png';
// import UpgradeDialogImg from './upgrade-dialog.png';
import { CreateProgramPopUp } from '../CreateProgramPopUp/CreateProgramPopUp';
import { ModalFooter } from '../../Core/Components/Modal/ModalFooter';
// import { CustomSelect } from '../../Core/Components/CustomSelect/CustomSelect';
import { SelfRegistrationDialog } from '../SelfRegistrationDialog/SelfRegistrationDialog';
import { PACKAGES_TYPES } from '../../models/packages/packages';
import { NeuroPromoDialog } from '../NeuroPromoDialog/NeuroPromoDialog';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { detectOS, downloadURIContent } from '../../utils/utils';
import { CustomersListFiltersMobile } from './CustomersListMobile/CustomersListFiltersMobile';
import { CustomersListFiltersRegular } from './CustomerListRegular/CustomersListFiltersRegular';
import { TableTitlesRegular } from './CustomerListRegular/TableTitlesRegular';
import { TableTitlesMobile } from './CustomersListMobile/TableTitlesMobile';

const initialValue = Symbol('iv');

const sortingOptions = [
  { value: 'sign-up', text: 'Sort by: Signed up', dbKey: 'signupTimestamp' },
  { value: 'first-name', text: 'Sort by: First name' },
  { value: 'last-name', text: 'Sort by: Last name' },
  { value: 'last-seen', text: 'Sort by: Last seen', dbKey: 'presence' },
  {
    value: 'last-assessment',
    text: 'Sort by: Last assessment',
  },
  {
    value: 'journey-completion',
    text: 'Sort by: Journey completion',
  },
  {
    value: 'last-neurofeedback-session',
    text: 'Sort by: Last neurofeedback session',
    hidden: true,
  },
  {
    value: 'neurofeedback-programs-left',
    text: 'Sort by: Neurofeedback programs left',
    hidden: true,
  },
  {
    value: 'latest-notification',
    text: 'Sort by: Latest notification',
  },
  {
    value: 'action-needed',
    text: 'Sort by: Action needed',
  },
];

const CSVColumns = [
  'Full Name',
  'Signed Up',
  'Last Seen',
  'Updates Notification Count',
  'To-do Notification Count',
  'Last Assessment',
  'Next Assessment',
  'Completed Assessments Count',
  'Last Session',
  'Current Program Progress',
  'Programs Completed',
];

const clientPageEventLogger = new MyEventWrapper('client_page');

const CustomEmailMaskingSwitch = withStyles(theme => ({
  root: {
    // padding: -8,
  },

  checked: {},
  disabled: {},
  icon: {
    boxShadow: 'none',
    width: 20,
    height: 20,
    margin: 2,
  },
  switchBase: {
    '&$checked': {
      transform: 'translateX(19.5px)', // Change this value to control the thumb movement distance
    },
  },
  bar: {
    height: 22,
    width: 42,
    borderRadius: 22 / 2,
    top: '42%',
    left: '48.5%',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      // ( ✔️ ) icon
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      right: 22,
    },
    '&::after': {
      // ( - ) icon
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      left: 22,
    },
  },
}))(Switch);

const CustomersListBase = () => {
  const didMountRef = useRef(false);
  const customerCardWrapperRef = useRef(null);

  // const [usersList, setUsersList] = useState(null)

  const [usersListData, setUsersListData] = useState(null);
  const [sortingSelect, setSortingSelect] = useState('');
  const [pageState, setPageState] = useState(0);
  const [filterModalState, setFilterModalState] = useState('');
  const [archiveCheckboxes, setArchiveCheckboxes] = useState({});

  // data for add client modal
  const [allQuestionnaires, setAllQuestionnaires] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const [assessmentStagesInfo, setAssessmentStagesInfo] = useState(null);
  const [toggleAddClient, setToggleAddClient] = useState(false);
  const [isRegistrationDialogOpen, setIsRegistrationDialogOpen] = useState(
    false
  );

  const [toggleRerender, setToggleRerender] = useState(false);
  const [clearFiltersRerender, setClearFiltersRerender] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [displayArchiveModal, setDisplayArchiveModal] = useState(false);
  const [displayRichedLimitModal, setDisplayRichedLimitModal] = useState(false);
  const [addClientReachedLimitModal, setAddClientReachedLimitModal] = useState(
    false
  );

  // Filter state:
  const [allCLientsState, setAllCLientsState] = useState(true);
  const [nameEmailInput, setNameEmailInput] = useState('');
  const [hasRemainingSessionsToggle, setHasRemainingSessionsToggle] = useState(
    null
  );

  const [completedSessionMin, setCompletedSessionMin] = useState('');
  const [completedSessionMax, setCompletedSessionMax] = useState('');

  const [
    doNotHaveUpcomingAssessmentToggle,
    setDoNotHaveUpcomingAssessmentToggle,
  ] = useState(null);

  const [completedAssessmentsMin, setCompletedAssessmentsMin] = useState('');
  const [completedAssessmentsMax, setCompletedAssessmentsMax] = useState('');
  const [journeyEnableToggle, setJourneyEnableToggle] = useState(null);
  const [completedJourneyMin, setCompletedJourneyMin] = useState('');
  const [completedJourneyMax, setCompletedJourneyMax] = useState('');
  const [actionNeededToggle, setActionNeededToggle] = useState(null);
  const [pendingJournalsToggle, setPendingJournalsToggle] = useState(null);
  const [hasProgressReportToggle, setHasProgressReportToggle] = useState(null);
  const [generatedReportsMin, setGeneratedReportsMin] = useState('');
  const [generatedReportsMax, setGeneratedReportsMax] = useState('');
  const [liveToggle, setLiveToggle] = useState(false);
  const [archiveToggle, setArchiveToggle] = useState(false);
  const [assignedToggle, setAssignedToggle] = useState(false);

  const [createProgramPopUpOpener, setCreateProgramPopUpOpener] = useState(
    null
  );

  const [scrolled, setScrolled] = useState('');

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingFBData, setIsLoadingFBData] = useState(true);

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [moreMenuAnchorElement, setMoreMenuAnchorElement] = useState(null);
  const [isLoadingAddClientData, setIsLoadingAddClientData] = useState(true);
  const [
    isAddClientClickedWhileLoading,
    setIsAddClientClickedWhileLoading,
  ] = useState(false);

  const [teamMembersMenuOpenId, setIsTeamMembersMenuOpen] = useState(null);

  // lazy list rendering
  // eslint-disable-next-line prefer-arrow-callback, space-before-function-paren
  // $(window).scroll(function() {
  //   if (
  //     $(window).scrollTop() + $(window).height() + 1 >=
  //     $(document).height()
  //   ) {
  //     setScrolled(scrolled + 0);
  //   }
  // });

  useEffect(() => {
    setPageState(pageState + 1);
  }, [scrolled]);

  const [clientNameMasking, setClientNameMasking] = useFirebaseDB({
    path: `/clinicConfig/clientNameMasking/${sessionStorage.userId}`,
    onceListener: true,
  });

  const [usersList] = useFirebaseDB({
    path: `clinicPatients/${sessionStorage.userId}`,
    defaultValue: useRef(0).current,
  });

  const [clinicUserInfo] = useFirebaseDB({
    path: `userInfo/${sessionStorage.userId}`,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
  });

  const [filtersConfig, setFiltersConfig] = useFirebaseDB({
    path: `clinics/${
      sessionStorage.userId
    }/configurations/clientsFilters/${sessionStorage.teamMemberId ||
      sessionStorage.userId}`,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
  });

  const [reachedClientsLimit] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/reachedClientsLimit`,
  });

  const [numberOfClients] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/numberOfUsers`,
  });

  const [usersIncluded] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/usersIncluded`,
    onceListener: true,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/nfAddOnPlanId`,
    onceListener: true,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/packageType`,
    onceListener: true,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  const [clinicNonfPlan] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}`,
    onceListener: true,
  });

  const [canAccessNf] = useFirebaseDB({
    path: `clinicConfig/nfFlag/${sessionStorage.userId}/isEnabled`,
    onceListener: true,
  });

  const [assessmentAutoDeployableConfigs] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedAssessment/content`,
    onceListener: true,
  });

  const [questionnairesAutoDeployableConfigs] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedQuestionnaires/content`,
    onceListener: true,
  });

  const [teamMembersList] = useFirebaseDB({
    path: `clinics/${sessionStorage.userId}/subEmails`,
    initialValue,
  });

  const onOpenSortMenu = event => setMenuAnchorElement(event.currentTarget);
  const onOpenMoreMenu = event => setMoreMenuAnchorElement(event.currentTarget);

  const shouldShowTeamMemberAssign =
    teamMembersList &&
    Object.keys(teamMembersList).find(id => teamMembersList[id].userId);

  useEffect(() => {
    if (teamMembersList !== initialValue) {
      setIsLoadingFBData(false);
    }
  }, [teamMembersList]);

  useEffect(() => {
    if (!isLoadingFBData && !isLoadingUsers) {
      clientPageEventLogger.log('page_loaded', {
        // eslint-disable-next-line no-undef
        loading_time: Date.now() - loadingTime,
        team_member_id: sessionStorage.teamMemberId,
        timestamp: Date.now(),
        is_compact_load: true,
        is_assigned_column_shown: !!shouldShowTeamMemberAssign,
      });
      didMountRef.current = true;
    }
  }, [isLoadingFBData, isLoadingUsers, shouldShowTeamMemberAssign]);

  useEffect(() => {
    (async () => {
      const [
        allQuestionnairesData,
        allTagsData,
        assessmentStagesInfoData,
        // eslint-disable-next-line no-undef
      ] = await loadDataForAddClientModal();
      setAllQuestionnaires(allQuestionnairesData);
      setAllTags(allTagsData);
      setAssessmentStagesInfo(assessmentStagesInfoData);
      setIsLoadingAddClientData(false);
      setIsAddClientClickedWhileLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // if (!didMountRef.current) {
      //   clientPageEventLogger.log('page_entered', {
      //     team_member_id: sessionStorage.teamMemberId,
      //     timestamp: Date.now(),
      //   });
      // }
      // const startedLoadingTime = Date.now();

      // const journeyEnabledRef = await database
      //   .ref('lago/educational/defaults/modules')
      //   .once('value');

      // let smallUsersList;
      // if (!usersList) {
      //   const sortTypeRef = await database
      //     .ref(
      //       `clinics/${
      //         sessionStorage.userId
      //       }/configurations/clientsFilters/${sessionStorage.teamMemberId ||
      //         sessionStorage.userId}/sortingType`
      //     )
      //     .once('value');

      //   if (!sortTypeRef.val()) {
      //     smallUsersList = await database
      //       .ref(`deployedSession/${sessionStorage.userId}`)
      //       .orderByChild('presence')
      //       .limitToLast(10)
      //       .once('value');
      //   } else {
      //     const option = sortingOptions.find(
      //       optionObj => optionObj.value === sortTypeRef.val()
      //     );
      //     if (option && option.dbKey) {
      //       smallUsersList = await database
      //         .ref(`deployedSession/${sessionStorage.userId}`)
      //         .orderByChild(option.dbKey)
      //         .limitToLast(10)
      //         .once('value');
      //     }
      //   }
      // }

      const finalUsersList = usersList;

      if (finalUsersList) {
        const dataMap = {};
        Object.keys(finalUsersList).map(async userId => {
          dataMap[userId] = {};
          dataMap[userId].assignedTo = finalUsersList[userId].assignedTo;
          dataMap[userId].isDemo = finalUsersList[userId].isDemo;
          dataMap[userId].firstName = finalUsersList[userId].firstName;
          dataMap[userId].lastName = finalUsersList[userId].lastName;
          dataMap[userId].email = finalUsersList[userId].email;
          dataMap[userId].signupTimestamp =
            finalUsersList[userId].signupTimestamp;
          dataMap[userId].archived = finalUsersList[userId].archived;
          let usersLastSessionTimestamp = 0;
          let usersProgramsLeft = 0;
          let usersProgramsSessionsLeft = 0;
          // let usersLastAssessmentTime = 0;
          let usersNumOfPrograms = 0;
          let usersTotalSessions = 0;
          // eslint-disable-next-line no-unused-expressions
          finalUsersList[userId].programs &&
            Object.keys(finalUsersList[userId].programs).forEach(programId => {
              usersNumOfPrograms += 1;
              usersTotalSessions +=
                finalUsersList[userId].programs[programId].sessionsInProgram;
              // find last neurofeedbacksession
              if (
                finalUsersList[userId].programs[programId].lastSessionDone &&
                finalUsersList[userId].programs[programId].lastSessionDone >
                  usersLastSessionTimestamp
              ) {
                usersLastSessionTimestamp =
                  finalUsersList[userId].programs[programId].lastSessionDone;
              }
              if (!finalUsersList[userId].programs[programId].isComplete) {
                usersProgramsLeft += 1;
                usersProgramsSessionsLeft +=
                  finalUsersList[userId].programs[programId].sessionsInProgram -
                  finalUsersList[userId].programs[programId]
                    .sessionsOfProgramDone;
              }
            });
          dataMap[userId].lastSessionDone = usersLastSessionTimestamp;
          dataMap[userId].usersProgramsLeft = usersProgramsLeft;
          dataMap[userId].usersProgramsSessionsLeft = usersProgramsSessionsLeft;
          dataMap[userId].usersNumOfPrograms = usersNumOfPrograms;
          dataMap[userId].usersTotalSessions = usersTotalSessions;
          if (
            finalUsersList[userId].status &&
            finalUsersList[userId].status.state
          ) {
            dataMap[userId].statusState = finalUsersList[userId].status.state;
          }

          dataMap[userId].isExpired =
            finalUsersList[userId] &&
            finalUsersList[userId].trainingExpiration &&
            finalUsersList[userId].trainingExpiration.expiration &&
            finalUsersList[userId].trainingExpiration.expiration < Date.now()
              ? finalUsersList[userId].trainingExpiration.expiration
              : '';

          dataMap[userId].assessments = {
            ...finalUsersList[userId].assessments,
          };
          dataMap[userId].numOfDoneAssessments = finalUsersList[userId]
            .assessments
            ? finalUsersList[userId].assessments.numAssessmentsDone || 0
            : 0;
          dataMap[userId].usersLastAssessmentTime = finalUsersList[userId]
            .assessments
            ? finalUsersList[userId].assessments.lastAssessmentsDoneTimestamp ||
              0
            : 0;

          const deploydAssessments = finalUsersList[userId].assessments
            ? finalUsersList[userId].assessments.deployedAssessments
            : null;
          if (deploydAssessments === null || deploydAssessments === undefined) {
            dataMap[userId].isUserHasAssessment = false;
          } else {
            dataMap[userId].isUserHasAssessment = true;
          }
          if (deploydAssessments) {
            dataMap[userId].assessmentOpenTimestamp =
              deploydAssessments.assessmentOpenTimestamp;
            dataMap[userId].intervalType = deploydAssessments.intervalType;
            dataMap[userId].minsUsage = deploydAssessments.minsUsage;
            dataMap[userId].frequency = deploydAssessments.frequency;
            dataMap[userId].numSessions = deploydAssessments.numSessions;
            dataMap[userId].afterTrainingDuration =
              deploydAssessments.afterTrainingDuration;
            dataMap[userId].isAssessmentOpen =
              deploydAssessments.isAssessmentOpen;
            dataMap[userId].recurring = deploydAssessments.recurring;
            dataMap[userId].beforeNextSession =
              deploydAssessments.beforeNextSession;
          }
          const assessmentCurrentStage = finalUsersList[userId].assessments
            ? finalUsersList[userId].assessments.currentStage
            : null;
          dataMap[userId].assessmentCurrentStage = assessmentCurrentStage;
          if (
            !finalUsersList[userId].userInfo ||
            !finalUsersList[userId].userInfo.presence ||
            finalUsersList[userId].userInfo.presence === null ||
            finalUsersList[userId].userInfo.presence === 1
          ) {
            dataMap[userId].userLastSeen = 0;
          } else if (finalUsersList[userId].userInfo.presence === 'online') {
            dataMap[userId].userLastSeen =
              finalUsersList[userId].userInfo.presence;
          } else {
            dataMap[userId].userLastSeen = +finalUsersList[userId].userInfo
              .presence;
          }
          if (
            !finalUsersList[userId].userInfo ||
            !finalUsersList[userId].userInfo.clinicianSetUpRequired
          ) {
            dataMap[userId].clinicianSetUpRequired = false;
          } else {
            dataMap[userId].clinicianSetUpRequired = true;
          }

          const journeyData = finalUsersList[userId].journeyDetails;
          if (journeyData) {
            if (
              journeyData.isEnabled === undefined ||
              journeyData.isEnabled === null ||
              journeyData.isEnabled === true
            ) {
              dataMap[userId].isJourneyEnabled = true;
            } else {
              dataMap[userId].isJourneyEnabled = false;
            }
            // let current = Object.keys(
            //   journeyData.journeyConfig.minicourses
            // ).find(minicourseId => {
            //   return journeyData.journeyConfig.minicourses[minicourseId]
            //     .percentageDone;
            // });
            // if (!current) {
            //   current = Object.keys(journeyData.journeyConfig.minicourses).find(
            //     minicourseId => {
            //       return (
            //         journeyData.journeyConfig.minicourses[minicourseId]
            //           .index === 0
            //       );
            //     }
            //   );
            // }
            // if (
            //   journeyData &&
            //   journeyData.currentCourse &&
            //   journeyData.currentCourse.currentModule &&
            //   journeyData.currentCourse.currentModule.moduleId &&
            //   journeyEnabledRef.val()[
            //     journeyData.currentCourse.currentModule.moduleId
            //   ]
            // ) {
            //   dataMap[userId].currentModule = journeyEnabledRef.val()[
            //     journeyData.currentCourse.currentModule.moduleId
            //   ].title.en;
            // }
            dataMap[userId].precentageDone = journeyData.percentageDone;
            dataMap[userId].journeyName = journeyData.title;
          } else {
            dataMap[userId].isJourneyEnabled = true;
            dataMap[userId].precentageDone = 0;
            dataMap[userId].currentModule = null;
            // dataMap[userId].currentCourseId = null;
          }
          let redFlagCounter = 0;
          let greenFlagCounter = 0;
          let messageCounter = 0;
          if (finalUsersList[userId].notifications) {
            dataMap[userId].notifications = {
              ...finalUsersList[userId].notifications,
            };
            redFlagCounter = finalUsersList[userId].notifications.redFlag || 0;
            greenFlagCounter =
              finalUsersList[userId].notifications.greenFlag || 0;
            messageCounter =
              (finalUsersList[userId].notifications.yellowMessage || 0) +
              (finalUsersList[userId].notifications.blueMessage || 0) +
              (finalUsersList[userId].notifications.message || 0) +
              (finalUsersList[userId].notifications.redMessage || 0);
          }
          dataMap[userId].notificationsCounter = {
            redFlagCounter,
            greenFlagCounter,
            messageCounter,
          };
          dataMap[userId].reportsCounter = finalUsersList[userId].reports
            ? finalUsersList[userId].reports.numReportsDone || 0
            : 0;

          // try {
          //   const customerInfoRef = await database
          //     .ref(`userInfo/${userId}`)
          //     .once('value');
          //   dataMap[userId].customerInfo = customerInfoRef.val();
          // } catch (err) {
          //   // console.log(err)
          // }
        });
        // await Promise.all(promises);
        setUsersListData(dataMap);
        setIsLoadingUsers(false);
      }
    })();
  }, [usersList, toggleRerender, sortingSelect]);

  useEffect(() => {
    if (filtersConfig) {
      if (
        filtersConfig.hasRemainingSessionsToggle === true ||
        filtersConfig.hasRemainingSessionsToggle === false
      ) {
        setHasRemainingSessionsToggle(filtersConfig.hasRemainingSessionsToggle);
      }
      // setNoSessionsLeftToggle(filtersConfig.noSessionsLeftToggle);
      setCompletedSessionMin(filtersConfig.completedSessionMin || '');
      setCompletedSessionMax(filtersConfig.completedSessionMax || '');
      // setCompleteOneAssessmentToggle(
      //   filtersConfig.completeOneAssessmentToggle
      // );
      // setCompleteMoreThanOneAssessmentToggle(
      //   filtersConfig.completeMoreThanOneAssessmentToggle
      // );
      if (
        filtersConfig.doNotHaveUpcomingAssessmentToggle === true ||
        filtersConfig.doNotHaveUpcomingAssessmentToggle === false
      ) {
        setDoNotHaveUpcomingAssessmentToggle(
          filtersConfig.doNotHaveUpcomingAssessmentToggle
        );
      }
      setCompletedAssessmentsMin(filtersConfig.completedAssessmentsMin || '');
      setCompletedAssessmentsMax(filtersConfig.completedAssessmentsMax || '');
      if (
        filtersConfig.journeyEnableToggle === true ||
        filtersConfig.journeyEnableToggle === false
      ) {
        setJourneyEnableToggle(filtersConfig.journeyEnableToggle);
      }
      // setJourneyDisableToggle(filtersConfig.journeyDisableToggle);
      setCompletedJourneyMin(filtersConfig.completedJourneyMin || '');
      setCompletedJourneyMax(filtersConfig.completedJourneyMax || '');
      if (
        filtersConfig.actionNeededToggle === true ||
        filtersConfig.actionNeededToggle === false
      ) {
        setActionNeededToggle(filtersConfig.actionNeededToggle);
      }
      // setActivityToggle(filtersConfig.activityToggle);
      if (
        filtersConfig.pendingJournalsToggle === true ||
        filtersConfig.pendingJournalsToggle === false
      ) {
        setPendingJournalsToggle(filtersConfig.pendingJournalsToggle);
      }
      if (
        filtersConfig.hasProgressReportToggle === true ||
        filtersConfig.hasProgressReportToggle === false
      ) {
        setHasProgressReportToggle(filtersConfig.hasProgressReportToggle);
      }
      setGeneratedReportsMin(filtersConfig.generatedReportsMin || '');
      setGeneratedReportsMax(filtersConfig.generatedReportsMax || '');
      if (filtersConfig.sortingType) {
        setSortingSelect(filtersConfig.sortingType);
      } else {
        setSortingSelect('last-seen');
      }
      if (
        filtersConfig.liveToggle === true ||
        filtersConfig.liveToggle === false
      ) {
        setLiveToggle(filtersConfig.liveToggle);
      }
      if (
        filtersConfig.assignedToggle === true ||
        filtersConfig.assignedToggle === false
      ) {
        setAssignedToggle(filtersConfig.assignedToggle);
      }
      if (
        filtersConfig.archiveToggle === true ||
        filtersConfig.archiveToggle === false
      ) {
        setArchiveToggle(filtersConfig.archiveToggle);
      }
      // for all clients button color
      if (
        (filtersConfig.actionNeededToggle === null ||
          filtersConfig.actionNeededToggle === undefined) &&
        filtersConfig.completedAssessmentsMax === '' &&
        filtersConfig.completedAssessmentsMin === '' &&
        filtersConfig.completedJourneyMax === '' &&
        filtersConfig.completedJourneyMin === '' &&
        filtersConfig.completedSessionMax === '' &&
        filtersConfig.completedSessionMin === '' &&
        (filtersConfig.doNotHaveUpcomingAssessmentToggle === null ||
          filtersConfig.doNotHaveUpcomingAssessmentToggle === undefined) &&
        filtersConfig.generatedReportsMax === '' &&
        filtersConfig.generatedReportsMin === '' &&
        (filtersConfig.hasProgressReportToggle === null ||
          filtersConfig.hasProgressReportToggle === undefined) &&
        (filtersConfig.hasRemainingSessionsToggle === null ||
          filtersConfig.hasRemainingSessionsToggle === undefined) &&
        (filtersConfig.journeyEnableToggle === null ||
          filtersConfig.journeyEnableToggle === undefined) &&
        (filtersConfig.pendingJournalsToggle === null ||
          filtersConfig.pendingJournalsToggle === undefined) &&
        filtersConfig.liveToggle === false &&
        (filtersConfig.assignedToggle === false ||
          filtersConfig.assignedToggle === undefined) &&
        filtersConfig.archiveToggle === false
      ) {
        setAllCLientsState(true);
        if (archiveToggle === true) {
          setAllCLientsState(false);
        }
      } else {
        setAllCLientsState(false);
      }
    }
  }, [usersList, filtersConfig]);

  const [selectedUserProgram, setSelectedUserProgram] = useState(null);

  const [
    isCreateProgramPopUpInAdvancedMode,
    setIsCreateProgramPopUpInAdvancedMode,
  ] = useState(sessionStorage.isSimpleDashboard !== 'true');

  /*
   this is the part for masking names and emails -------
   */

  const isAdminLogin = sessionStorage.getItem('isLoggedByAdmin');

  const [isNameMasking, setIsNameMasking] = useState(isAdminLogin);

  useEffect(() => {
    if (!isAdminLogin && clientNameMasking) {
      setIsNameMasking(clientNameMasking.isEnabled);
    }
  }, [clientNameMasking]);

  const cache = new Map();
  const CACHE_LIMIT = 100;
  const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes

  const maskText = (text, textType) => {
    if (!text || text.length === 0) return;

    const cacheKey = `${textType}:${text}`;
    const currentTime = Date.now();

    if (cache.has(cacheKey)) {
      const { value, expiration } = cache.get(cacheKey);

      // eslint-disable-next-line
      if (currentTime < expiration) return value;
      cache.delete(cacheKey);
    }

    let maskedText;

    switch (textType) {
      case 'email': {
        const [localPart, domainPart] = text.split('@');
        const localPartLength = localPart.length;
        if (localPartLength > 3) {
          const obfuscatedLocalPart = `${localPart.slice(0, 3)}${'*'.repeat(
            localPartLength - 3
          )}`;
          maskedText = `${obfuscatedLocalPart}@${domainPart}`;
        } else {
          const obfuscatedLocalPart = `${localPart.slice(
            0,
            localPartLength - 1
          )}*`;
          maskedText = `${obfuscatedLocalPart}@${domainPart}`;
        }
        break;
      }
      case 'name': {
        const obfuscatedName = text
          .split(' ')
          .map(n => {
            return n.length > 3
              ? `${n.slice(0, 2)}${'*'.repeat(n.length - 2)}`
              : `${n.slice(0, n.length - 1)}*`;
          })
          .join(' ');
        maskedText = obfuscatedName;
        break;
      }
      default:
        maskedText = text;
        break;
    }

    if (cache.size >= CACHE_LIMIT) {
      const firstKey = cache.keys().next().value;
      cache.delete(firstKey);
    }

    cache.set(cacheKey, {
      value: maskedText,
      expiration: currentTime + EXPIRATION_TIME,
    });
    // eslint-disable-next-line
    return maskedText;
  };

  useEffect(() => {
    return () => {
      // Cleanup function: Clear the cache when component unmounts
      cache.clear();
    };
  }, []); // Empty dependency array ensures this runs only on unmount

  /*
  end of masking  ---------------------------
   */

  const closeCreateProgramPopUpModal = useCallback(() => {
    // userPageLogger.log('on_close_create_program_pop_modal');
    setSelectedUserProgram(null);
    setCreateProgramPopUpOpener(false);
    setToggleRerender(!toggleRerender);
  }, [setCreateProgramPopUpOpener, createProgramPopUpOpener]);

  const onEditPrograms = useCallback(userId => {
    setSelectedUserProgram(userId);
    setCreateProgramPopUpOpener({ isEditingMode: false });
  }, []);

  const onProgramSettingsAdvancedModeChange = event => {
    const mode = event.target.checked;

    setIsCreateProgramPopUpInAdvancedMode(mode);
    sessionStorage.isSimpleDashboard = !mode;
    database
      .ref('clinics')
      .child(sessionStorage.userId)
      .child('dashboardType')
      .set(mode ? 'advanced' : 'basic');

    const eventName = mode
      ? 'advanced_mode_on_clicked'
      : 'basic_mode_on_clicked';
    new MyEvent(eventName).log();
  };

  const filterByArchived = userId => {
    return archiveToggle && usersListData && usersListData[userId].archived;
  };

  const filterNotArchived = userId => {
    return usersListData && !usersListData[userId].archived;
  };

  const filterByNameEmail = userId => {
    return (
      !nameEmailInput ||
      (usersListData[userId].email &&
        usersListData[userId].email
          .toLowerCase()
          .includes(nameEmailInput.toLowerCase())) ||
      (usersListData[userId].firstName &&
        usersListData[userId].firstName
          .toLowerCase()
          .includes(nameEmailInput.toLowerCase())) ||
      (usersListData[userId].lastName &&
        usersListData[userId].lastName
          .toLowerCase()
          .includes(nameEmailInput.toLowerCase())) ||
      (usersListData[userId].lastName &&
        usersListData[userId].firstName &&
        // eslint-disable-next-line prefer-template
        (usersListData[userId].firstName + ' ' + usersListData[userId].lastName)
          .toLowerCase()
          .includes(nameEmailInput.toLowerCase())) ||
      (usersListData[userId].lastName &&
        usersListData[userId].firstName &&
        // eslint-disable-next-line prefer-template
        (usersListData[userId].lastName + ' ' + usersListData[userId].firstName)
          .toLowerCase()
          .includes(nameEmailInput.toLowerCase()))
    );
  };

  const filterByHasRemainingSessions = userId => {
    return (
      filtersConfig.hasRemainingSessionsToggle === null ||
      filtersConfig.hasRemainingSessionsToggle === undefined ||
      filtersConfig.hasRemainingSessionsToggle === false ||
      (filtersConfig.hasRemainingSessionsToggle === true &&
        usersListData &&
        usersListData[userId] &&
        usersListData[userId].usersProgramsSessionsLeft > 0)
    );
  };

  const filterByHasNoRemainingSessions = userId => {
    return (
      filtersConfig.hasRemainingSessionsToggle === null ||
      filtersConfig.hasRemainingSessionsToggle === undefined ||
      filtersConfig.hasRemainingSessionsToggle === true ||
      (filtersConfig.hasRemainingSessionsToggle === false &&
        usersListData &&
        usersListData[userId] &&
        usersListData[userId].usersProgramsSessionsLeft <= 0)
    );
  };

  const filterByCompletedSessionsMin = userId => {
    return (
      !filtersConfig.completedSessionMin ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].usersTotalSessions -
          usersListData[userId].usersProgramsSessionsLeft >=
          filtersConfig.completedSessionMin)
    );
  };

  const filterByCompletedSessionsMax = userId => {
    return (
      !filtersConfig.completedSessionMax ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].usersTotalSessions -
          usersListData[userId].usersProgramsSessionsLeft <=
          filtersConfig.completedSessionMax)
    );
  };

  const filterByDoNotHaveUpcomingAssessment = userId => {
    return (
      filtersConfig.doNotHaveUpcomingAssessmentToggle === null ||
      filtersConfig.doNotHaveUpcomingAssessmentToggle === undefined ||
      filtersConfig.doNotHaveUpcomingAssessmentToggle === true ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].assessments &&
        usersListData[userId].assessments.deployedAssessments &&
        !(
          usersListData[userId].assessments.deployedAssessments.recurring ||
          usersListData[userId].assessments.deployedAssessments
            .beforeNextSession
        )) ||
      (usersListData &&
        usersListData[userId] &&
        !usersListData[userId].assessments)
    );
  };

  const filterByHaveUpcomingAssessment = userId => {
    return (
      filtersConfig.doNotHaveUpcomingAssessmentToggle === null ||
      filtersConfig.doNotHaveUpcomingAssessmentToggle === undefined ||
      filtersConfig.doNotHaveUpcomingAssessmentToggle === false ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].assessments &&
        usersListData[userId].assessments.deployedAssessments &&
        (usersListData[userId].assessments.deployedAssessments.recurring ||
          usersListData[userId].assessments.deployedAssessments
            .beforeNextSession))
    );
  };

  const filterByCompletedAssessmentsMin = userId => {
    return (
      !filtersConfig.completedAssessmentsMin ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].numOfDoneAssessments >=
          filtersConfig.completedAssessmentsMin)
    );
  };

  const filterByCompletedAssessmentsMax = userId => {
    return (
      !filtersConfig.completedAssessmentsMax ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].numOfDoneAssessments <=
          filtersConfig.completedAssessmentsMax)
    );
  };

  const filterByJourneyEnabled = userId => {
    return (
      filtersConfig.journeyEnableToggle === null ||
      filtersConfig.journeyEnableToggle === undefined ||
      filtersConfig.journeyEnableToggle === false ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].isJourneyEnabled)
    );
  };

  const filterByJourneyDisabled = userId => {
    return (
      filtersConfig.journeyEnableToggle === null ||
      filtersConfig.journeyEnableToggle === undefined ||
      filtersConfig.journeyEnableToggle === true ||
      (usersListData &&
        usersListData[userId] &&
        !usersListData[userId].isJourneyEnabled)
    );
  };

  const filterByCompletedJourneyMin = userId => {
    return (
      !filtersConfig.completedJourneyMin ||
      (usersListData &&
        usersListData[userId].isJourneyEnabled &&
        usersListData[userId].precentageDone >=
          filtersConfig.completedJourneyMin)
    );
  };

  const filterByCompletedJourneyMax = userId => {
    return (
      !filtersConfig.completedJourneyMax ||
      (usersListData &&
        usersListData[userId].isJourneyEnabled &&
        usersListData[userId].precentageDone <=
          filtersConfig.completedJourneyMax)
    );
  };

  const filterByActionNeeded = userId => {
    return (
      filtersConfig.actionNeededToggle === null ||
      filtersConfig.actionNeededToggle === undefined ||
      filtersConfig.actionNeededToggle === false ||
      (usersListData &&
        usersListData[userId].notificationsCounter.greenFlagCounter +
          usersListData[userId].notificationsCounter.redFlagCounter >
          0)
    );
  };

  const filterByNotActionNeeded = userId => {
    return (
      filtersConfig.actionNeededToggle === null ||
      filtersConfig.actionNeededToggle === undefined ||
      filtersConfig.actionNeededToggle === true ||
      (usersListData &&
        usersListData[userId].notificationsCounter.greenFlagCounter +
          usersListData[userId].notificationsCounter.redFlagCounter ===
          0)
    );
  };

  const filterByPendingJournals = userId => {
    return (
      filtersConfig.pendingJournalsToggle === null ||
      filtersConfig.pendingJournalsToggle === undefined ||
      filtersConfig.pendingJournalsToggle === false ||
      (usersListData &&
        usersListData[userId].notificationsCounter.messageCounter > 0)
    );
  };

  const filterByNotPendingJournals = userId => {
    return (
      filtersConfig.pendingJournalsToggle === null ||
      filtersConfig.pendingJournalsToggle === undefined ||
      filtersConfig.pendingJournalsToggle === true ||
      (usersListData &&
        usersListData[userId].notificationsCounter.messageCounter === 0)
    );
  };

  const filterByHasProgressReport = userId => {
    return (
      filtersConfig.hasProgressReportToggle === null ||
      filtersConfig.hasProgressReportToggle === undefined ||
      filtersConfig.hasProgressReportToggle === false ||
      (usersListData && usersListData[userId].reportsCounter > 0)
    );
  };

  const filterByNotHasProgressReport = userId => {
    return (
      filtersConfig.hasProgressReportToggle === null ||
      filtersConfig.hasProgressReportToggle === undefined ||
      filtersConfig.hasProgressReportToggle === true ||
      (usersListData && usersListData[userId].reportsCounter === 0)
    );
  };

  const filterByGeneratedReportsMin = userId => {
    return (
      !filtersConfig.generatedReportsMin ||
      (usersListData &&
        usersListData[userId].reportsCounter >=
          filtersConfig.generatedReportsMin)
    );
  };

  const filterByGeneratedReportsMax = userId => {
    return (
      !filtersConfig.generatedReportsMax ||
      (usersListData &&
        usersListData[userId].reportsCounter <=
          filtersConfig.generatedReportsMax)
    );
  };

  const filterByLive = userId => {
    return (
      !liveToggle ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].userLastSeen === 'online' &&
        usersListData[userId].statusState !== 'offline')
    );
  };

  const filterByAssigned = userId => {
    return (
      !assignedToggle ||
      (usersListData &&
        usersListData[userId] &&
        usersListData[userId].assignedTo &&
        usersListData[userId].assignedTo[sessionStorage.teamMemberId])
    );
  };

  const filterModalDisplayClicked = (modalName, e) => {
    setAnchorEl(e.currentTarget);
    if (filterModalState === modalName) {
      setFilterModalState('');
    } else {
      setFilterModalState(modalName);
    }
  };

  const clearFiltersClicked = () => {
    setAllCLientsState(true);
    setNameEmailInput('');
    setHasRemainingSessionsToggle(null);
    setCompletedSessionMin('');
    setCompletedSessionMax('');
    setCompletedAssessmentsMin('');
    setCompletedAssessmentsMax('');
    setDoNotHaveUpcomingAssessmentToggle(null);
    setJourneyEnableToggle(null);
    setCompletedJourneyMin('');
    setCompletedJourneyMax('');
    setActionNeededToggle(null);
    setPendingJournalsToggle(null);
    setHasProgressReportToggle(null);
    setGeneratedReportsMin('');
    setGeneratedReportsMax('');
    setArchiveToggle(false);
    setLiveToggle(false);
    setAssignedToggle(false);
    setPageState(0);
    // eslint-disable-next-line no-use-before-define
    onFilterChange();
  };

  const countUsersToArchive = shouldFilterSelfRegister => {
    let counter = 0;
    Object.keys(archiveCheckboxes).forEach(userId => {
      if (
        archiveCheckboxes[userId] &&
        (!shouldFilterSelfRegister ||
          !usersListData[userId].clinicianSetUpRequired)
      ) {
        counter += 1;
      }
    });
    return counter;
  };

  const onArchiveClicked = () => {
    if (!countUsersToArchive()) {
      return;
    }
    if (
      archiveToggle &&
      clinicNonfPlan &&
      countUsersToArchive(true) + numberOfClients > usersIncluded
    ) {
      setDisplayRichedLimitModal(true);
      return;
    }
    setDisplayArchiveModal(true);
  };

  const onArchiveConfirmed = () => {
    clientPageEventLogger.log('item_clicked', {
      button_clicked: !archiveToggle ? 'archieve_client' : 'unarchieve_client',
      archived_user_id: Object.keys(archiveCheckboxes),
      team_member_id: sessionStorage.teamMemberId,
      search_box_value: nameEmailInput,
    });
    Object.keys(archiveCheckboxes).forEach(async userId => {
      await database
        .ref(`deployedSession/${sessionStorage.userId}/${userId}/archived`)
        .set(
          archiveToggle ? !archiveCheckboxes[userId] : archiveCheckboxes[userId]
        );
    });
    setArchiveCheckboxes({});
    setDisplayArchiveModal(false);
  };

  const onViewPricingPlansClicked = async () => {
    await new MyEvent('view_pricing_plans_clicked').log({
      from_where: 'clients_page',
    });
    window.open('new_account.html', '_blank');
  };

  const onDeleteUsersClicked = () => {
    if (!countUsersToArchive()) {
      return;
    }
    const usersToDelete = Object.keys(archiveCheckboxes).filter(
      clientId => archiveCheckboxes[clientId]
    );
    let usersToDeleteNames = '';
    let usersToDeleteIds = '';
    let isContainSampleUser = false;
    usersToDelete.forEach(clientId => {
      usersToDeleteNames += `${usersListData[clientId].firstName} ${usersListData[clientId].lastName}, `;
      usersToDeleteIds += `${clientId}, `;
      if (usersListData[clientId].isDemo) {
        isContainSampleUser = true;
      }
    });
    if (isContainSampleUser) {
      Swal.fire({
        title: 'Delete Failed!',
        text: 'You cannot delete sample user',
        icon: 'error',
        confirmButtonColor: '#2196f3',
        confirmButtonText: 'OK',
      });
      return;
    }
    new MyEvent('on_trying_to_delete_user').log({
      deleted_user_ids: usersToDeleteIds,
    });
    Swal.fire({
      title: 'Are you sure?',
      text:
        // eslint-disable-next-line prefer-template
        'You are about to delete user ' +
        usersToDeleteNames +
        ' if you press "Yes", all of the data will be deleted and cannot be recovered. Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          clientPageEventLogger.log('item_clicked', {
            button_clicked: 'delete_user',
            deleted_user_ids: usersToDelete,
          });
          // eslint-disable-next-line no-undef
          await fireFunctionPost('clinic_apis-deletePatients', {
            patientIds: usersToDelete,
          });
          return;
        } catch (err) {
          setArchiveCheckboxes({});
          Swal.fire({
            title: 'Delete Failed!',
            text: 'Failed to delete user',
            icon: 'error',
            confirmButtonColor: '#2196f3',
            confirmButtonText: 'OK',
          });
        }
      },
    }).then(result => {
      if (result.value) {
        setArchiveCheckboxes({});
        Swal.fire({
          title: 'Deleted!',
          text: 'User has been deleted.',
          icon: 'success',
          confirmButtonColor: '#2196f3',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  // const onArchiveSelect = e => {
  //   e.stopPropagation();
  //   if (e.target.value === 'unarchive') {
  //     onArchiveClicked();
  //   }
  //   if (e.target.value === 'delete') {
  //     onDeleteUsersClicked();
  //   }
  //   if (e.target.value === 'export') {
  //     // eslint-disable-next-line no-use-before-define
  //     onExportUsersListToCSV();
  //   }
  // };

  const onFilterChange = () => {
    setPageState(0);
    const copyNewFiltersConfig = {
      hasRemainingSessionsToggle:
        hasRemainingSessionsToggle === true ||
        hasRemainingSessionsToggle === false
          ? // eslint-disable-next-line no-unneeded-ternary
            hasRemainingSessionsToggle
          : null,
      //   noSessionsLeftToggle,
      // eslint-disable-next-line no-unneeded-ternary
      completedSessionMin: completedSessionMin ? completedSessionMin : '',
      // eslint-disable-next-line no-unneeded-ternary
      completedSessionMax: completedSessionMax ? completedSessionMax : '',
      doNotHaveUpcomingAssessmentToggle:
        doNotHaveUpcomingAssessmentToggle === true ||
        doNotHaveUpcomingAssessmentToggle === false
          ? // eslint-disable-next-line no-unneeded-ternary
            doNotHaveUpcomingAssessmentToggle
          : null,
      completedAssessmentsMin: completedAssessmentsMin
        ? // eslint-disable-next-line no-unneeded-ternary
          completedAssessmentsMin
        : '',
      completedAssessmentsMax: completedAssessmentsMax
        ? // eslint-disable-next-line no-unneeded-ternary
          completedAssessmentsMax
        : '',
      // eslint-disable-next-line no-unneeded-ternary
      journeyEnableToggle:
        journeyEnableToggle === true || journeyEnableToggle === false
          ? journeyEnableToggle
          : null,
      // eslint-disable-next-line no-unneeded-ternary
      completedJourneyMin: completedJourneyMin ? completedJourneyMin : '',
      // eslint-disable-next-line no-unneeded-ternary
      completedJourneyMax: completedJourneyMax ? completedJourneyMax : '',
      // eslint-disable-next-line no-unneeded-ternary
      actionNeededToggle:
        actionNeededToggle === true || actionNeededToggle === false
          ? actionNeededToggle
          : null,
      // eslint-disable-next-line no-unneeded-ternary
      pendingJournalsToggle:
        pendingJournalsToggle === true || pendingJournalsToggle === false
          ? pendingJournalsToggle
          : null,
      hasProgressReportToggle:
        hasProgressReportToggle === true || hasProgressReportToggle === false
          ? // eslint-disable-next-line no-unneeded-ternary
            hasProgressReportToggle
          : null,
      // eslint-disable-next-line no-unneeded-ternary
      generatedReportsMin: generatedReportsMin ? generatedReportsMin : '',
      // eslint-disable-next-line no-unneeded-ternary
      generatedReportsMax: generatedReportsMax ? generatedReportsMax : '',
      liveToggle,
      assignedToggle,
      archiveToggle,
    };
    setFiltersConfig(copyNewFiltersConfig);
    setFilterModalState('');
    setClearFiltersRerender(!clearFiltersRerender);
  };
  //   eslint-disable-next-line consistent-return
  // useEffect(() => {
  //   if (didMountRef.current) return;
  //   didMountRef.current = true;
  // }, []);

  useEffect(() => {
    if (clearFiltersRerender !== null) {
      const copyNewFiltersConfig = {
        hasRemainingSessionsToggle:
          hasRemainingSessionsToggle === true ||
          hasRemainingSessionsToggle === false
            ? // eslint-disable-next-line no-unneeded-ternary
              hasRemainingSessionsToggle
            : null,
        //   noSessionsLeftToggle,
        completedSessionMin,
        completedSessionMax,
        doNotHaveUpcomingAssessmentToggle:
          doNotHaveUpcomingAssessmentToggle === true ||
          doNotHaveUpcomingAssessmentToggle === false
            ? // eslint-disable-next-line no-unneeded-ternary
              doNotHaveUpcomingAssessmentToggle
            : null,
        completedAssessmentsMin,
        completedAssessmentsMax,
        // eslint-disable-next-line no-unneeded-ternary
        journeyEnableToggle:
          journeyEnableToggle === true || journeyEnableToggle === false
            ? journeyEnableToggle
            : null,
        completedJourneyMin,
        completedJourneyMax,
        // eslint-disable-next-line no-unneeded-ternary
        actionNeededToggle:
          actionNeededToggle === true || actionNeededToggle === false
            ? actionNeededToggle
            : null,
        // eslint-disable-next-line no-unneeded-ternary
        pendingJournalsToggle:
          pendingJournalsToggle === true || pendingJournalsToggle === false
            ? pendingJournalsToggle
            : null,
        hasProgressReportToggle:
          hasProgressReportToggle === true || hasProgressReportToggle === false
            ? // eslint-disable-next-line no-unneeded-ternary
              hasProgressReportToggle
            : null,
        generatedReportsMin,
        generatedReportsMax,
        liveToggle,
        assignedToggle,
        archiveToggle,
      };
      setFiltersConfig(copyNewFiltersConfig);
      setFilterModalState('');
      // for all clients button color
      if (
        copyNewFiltersConfig.actionNeededToggle === null &&
        copyNewFiltersConfig.completedAssessmentsMax === '' &&
        copyNewFiltersConfig.completedAssessmentsMin === '' &&
        copyNewFiltersConfig.completedJourneyMax === '' &&
        copyNewFiltersConfig.completedJourneyMin === '' &&
        copyNewFiltersConfig.completedSessionMax === '' &&
        copyNewFiltersConfig.completedSessionMin === '' &&
        copyNewFiltersConfig.doNotHaveUpcomingAssessmentToggle === null &&
        copyNewFiltersConfig.generatedReportsMax === '' &&
        copyNewFiltersConfig.generatedReportsMin === '' &&
        copyNewFiltersConfig.hasProgressReportToggle === null &&
        copyNewFiltersConfig.hasRemainingSessionsToggle === null &&
        copyNewFiltersConfig.journeyEnableToggle === null &&
        copyNewFiltersConfig.pendingJournalsToggle === null &&
        copyNewFiltersConfig.liveToggle === false &&
        copyNewFiltersConfig.assignedToggle === false &&
        copyNewFiltersConfig.archiveToggle === false
      ) {
        setAllCLientsState(true);
        if (archiveToggle === true) {
          setAllCLientsState(false);
        }
      } else {
        setAllCLientsState(false);
      }
    }
  }, [clearFiltersRerender]);

  const usersListArchiveToggle = usersListData
    ? Object.keys(usersListData).filter(userId => {
        if (archiveToggle) {
          return filterByArchived(userId);
        }
        return filterNotArchived(userId);
      })
    : [];

  const filteredUsersIds = usersListData
    ? usersListArchiveToggle.filter(userId => {
        if (filtersConfig) {
          return (
            filterByNameEmail(userId) &&
            filterByHasRemainingSessions(userId) &&
            filterByHasNoRemainingSessions(userId) &&
            filterByCompletedSessionsMin(userId) &&
            filterByCompletedSessionsMax(userId) &&
            filterByCompletedAssessmentsMin(userId) &&
            filterByCompletedAssessmentsMax(userId) &&
            filterByDoNotHaveUpcomingAssessment(userId) &&
            filterByHaveUpcomingAssessment(userId) &&
            filterByJourneyEnabled(userId) &&
            filterByJourneyDisabled(userId) &&
            filterByCompletedJourneyMin(userId) &&
            filterByCompletedJourneyMax(userId) &&
            filterByActionNeeded(userId) &&
            filterByNotActionNeeded(userId) &&
            filterByPendingJournals(userId) &&
            filterByNotPendingJournals(userId) &&
            filterByHasProgressReport(userId) &&
            filterByNotHasProgressReport(userId) &&
            filterByGeneratedReportsMin(userId) &&
            filterByGeneratedReportsMax(userId) &&
            filterByAssigned(userId) &&
            filterByLive(userId)
          );
        }
        return true;
      })
    : [];

  if (sortingSelect && usersListData) {
    // eslint-disable-next-line array-callback-return, consistent-return
    filteredUsersIds.sort((a, b) => {
      if (!usersListData[a]) return -1;
      if (!usersListData[b]) return 1;
      if (sortingSelect === 'last-neurofeedback-session') {
        return (
          usersListData[b].lastSessionDone - usersListData[a].lastSessionDone
        );
      }
      if (sortingSelect === 'sign-up') {
        if (!usersListData[a].signupTimestamp) return 1;
        if (!usersListData[b].signupTimestamp) return -1;
        return (
          usersListData[b].signupTimestamp - usersListData[a].signupTimestamp
        );
      }
      if (sortingSelect === 'first-name') {
        if (!usersListData[a].firstName) return 1;
        if (!usersListData[b].firstName) return -1;
        if (
          usersListData[b].firstName.toUpperCase() >
          usersListData[a].firstName.toUpperCase()
        ) {
          return -1;
        }
        return 1;
      }
      if (sortingSelect === 'last-name') {
        if (!usersListData[a].lastName) return 1;
        if (!usersListData[b].lastName) return -1;
        if (
          usersListData[b].lastName.toUpperCase() >
          usersListData[a].lastName.toUpperCase()
        ) {
          return -1;
        }
        return 1;
      }
      if (sortingSelect === 'neurofeedback-programs-left') {
        return (
          usersListData[b].usersProgramsLeft -
          usersListData[a].usersProgramsLeft
        );
      }
      if (sortingSelect === 'journey-completion') {
        if (!usersListData[a].isJourneyEnabled) return 1;
        if (!usersListData[b].isJourneyEnabled) return -1;
        if (!usersListData[a].precentageDone) return 1;
        if (!usersListData[b].precentageDone) return -1;
        return (
          usersListData[b].precentageDone - usersListData[a].precentageDone
        );
      }
      if (sortingSelect === 'last-assessment') {
        return (
          usersListData[b].usersLastAssessmentTime -
          usersListData[a].usersLastAssessmentTime
        );
      }
      if (sortingSelect === 'last-seen') {
        if (usersListData[b].userLastSeen === 'online') {
          return 1;
        }
        if (usersListData[a].userLastSeen === 'online') {
          return -1;
        }
        if (usersListData[a].userLastSeen <= 1) {
          return 1;
        }
        if (usersListData[b].userLastSeen <= 1) {
          return -1;
        }
        return usersListData[b].userLastSeen - usersListData[a].userLastSeen;
      }
      if (sortingSelect === 'latest-notification') {
        if (!usersListData[a].notifications) return 1;
        if (!usersListData[b].notifications) return -1;
        // find largest lexical key for each user and compare
        let maxA = '';
        Object.keys(usersListData[a].notifications).forEach(notificationId => {
          if (notificationId > maxA) {
            maxA = notificationId;
          }
        });
        let maxB = '';
        Object.keys(usersListData[b].notifications).forEach(notificationId => {
          if (notificationId > maxB) {
            maxB = notificationId;
          }
        });
        return maxB.localeCompare(maxA);
      }
      if (sortingSelect === 'action-needed') {
        return (
          usersListData[b].notificationsCounter.redFlagCounter +
          usersListData[b].notificationsCounter.greenFlagCounter -
          (usersListData[a].notificationsCounter.redFlagCounter +
            usersListData[a].notificationsCounter.greenFlagCounter)
        );
      }
    });
    filteredUsersIds.sort(a => (usersListData[a].isDemo ? -1 : 1));
  }

  const calcNextAssessment = (clientId, achievementsUsages) => {
    if (
      !usersListData[clientId].isAssessmentOpen &&
      !usersListData[clientId].recurring
    ) {
      return 'None';
    }
    if (usersListData[clientId].beforeNextSession) {
      return 'On next app access';
    }
    const dayInMins = 86400000;
    let nextRecurringAssessmentTime =
      usersListData[clientId] &&
      usersListData[clientId].assessmentOpenTimestamp &&
      Math.ceil(
        (usersListData[clientId].assessmentOpenTimestamp - Date.now()) /
          dayInMins
      );
    if (
      usersListData[clientId] &&
      usersListData[clientId].intervalType === 'sessions'
    ) {
      nextRecurringAssessmentTime = Math.max(
        usersListData[clientId].numSessions +
          usersListData[clientId].frequency -
          (achievementsUsages[clientId]
            ? achievementsUsages[clientId].totalUsage.numOfSessions
            : 0),
        0
      );
      if (nextRecurringAssessmentTime === 0) {
        return 'On next app access';
      }
      return (
        // eslint-disable-next-line prefer-template
        nextRecurringAssessmentTime +
        ' ' +
        (nextRecurringAssessmentTime === 1 ? 'session' : 'sessions')
      );
    }
    if (
      usersListData[clientId] &&
      usersListData[clientId].intervalType === 'minutes'
    ) {
      const duration = usersListData[clientId].frequency
        ? usersListData[clientId].frequency
        : usersListData[clientId].afterTrainingDuration;
      nextRecurringAssessmentTime = Math.round(
        usersListData[clientId].minsUsage +
          duration -
          (achievementsUsages[clientId]
            ? achievementsUsages[clientId].totalUsage.minsUsage
            : 0)
      );
      if (nextRecurringAssessmentTime === 0) {
        return 'On next app access';
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(nextRecurringAssessmentTime)) {
        return 'None';
      }
      return (
        // eslint-disable-next-line prefer-template
        nextRecurringAssessmentTime +
        ' ' +
        (nextRecurringAssessmentTime === 1 ? 'minute' : 'minutes')
      );
    }
    if (
      usersListData[clientId] &&
      !usersListData[clientId].assessmentOpenTimestamp
    ) {
      return 'None';
    }
    if (
      usersListData[clientId] &&
      usersListData[clientId].assessmentOpenTimestamp
    ) {
      if (nextRecurringAssessmentTime > 0) {
        return (
          // eslint-disable-next-line prefer-template
          nextRecurringAssessmentTime +
          ' ' +
          (nextRecurringAssessmentTime === 1 ? 'day' : 'days')
        );
      }
      return 'On next app access';
    }
    return 'None';
  };

  function timeConverter(unixTimestamp, american = false) {
    const a = new Date(unixTimestamp);
    // eslint-disable-next-line no-restricted-globals
    if (!(a instanceof Date && !isNaN(a)) || unixTimestamp === 0) {
      return 'None';
    }
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    // const hour = a.getHours();
    // const min = a.getMinutes();
    // const sec = a.getSeconds();

    const time = american
      ? // eslint-disable-next-line prefer-template
        month + ' ' + date + ' ' + year
      : // eslint-disable-next-line prefer-template
        date + ' ' + month + ' ' + year;
    // + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  const onExportUsersListToCSV = async () => {
    clientPageEventLogger.log('item_clicked', {
      button_clicked: 'export_as_csv ',
      team_member_id: sessionStorage.teamMemberId,
    });
    const csvArray = [[...CSVColumns]];

    const achievementsUsages = await database
      .ref('achievements/usages')
      .once('value')
      .then(snapshot => snapshot.val());

    filteredUsersIds.forEach(clientId => {
      const clientData = [
        `${usersListData[clientId].firstName} ${usersListData[clientId].lastName}`,
        timeConverter(usersListData[clientId].signupTimestamp),
        timeConverter(usersListData[clientId].userLastSeen),
        usersListData[clientId].notificationsCounter.greenFlagCounter,
        usersListData[clientId].notificationsCounter.redFlagCounter,
        usersListData[clientId].usersLastAssessmentTime
          ? timeConverter(usersListData[clientId].usersLastAssessmentTime)
          : 'None',
        calcNextAssessment(clientId, achievementsUsages),
        usersListData[clientId].numOfDoneAssessments,
        usersListData[clientId].lastSessionDone
          ? timeConverter(usersListData[clientId].lastSessionDone)
          : 'None',
        usersListData[clientId].usersTotalSessions -
          usersListData[clientId].usersProgramsSessionsLeft ===
          0 && usersListData[clientId].usersTotalSessions === 0
          ? 'None'
          : ` ${usersListData[clientId].usersTotalSessions -
              usersListData[clientId].usersProgramsSessionsLeft} / ${
              usersListData[clientId].usersTotalSessions
            }`,
        usersListData[clientId].usersNumOfPrograms -
          usersListData[clientId].usersProgramsLeft ===
          0 && usersListData[clientId].usersNumOfPrograms === 0
          ? 'None'
          : ` ${usersListData[clientId].usersNumOfPrograms -
              usersListData[clientId].usersProgramsLeft} / ${
              usersListData[clientId].usersNumOfPrograms
            }`,
      ];
      // console.log(usersListData[clientId])
      csvArray.push(clientData);
    });

    const csv = convertArrayToCSV(csvArray.slice(1), {
      header: csvArray[0],
    });
    return downloadURIContent({
      content: `data:text/csv;charset=utf-8,${csv}`,
      name: `Clients Data Report ${timeConverter(Date.now(), true)}.csv`,
    });
  };

  // handle scroll event --- placed after variables initialization used in condition
  const os = detectOS();
  useEffect(() => {
    const extraScroll = os === 'Android' ? $(window).height() * 0.1 : 1;
    const handleScroll = throttle(() => {
      if (
        $(window).scrollTop() + $(window).height() + extraScroll >=
        $(document).height()
      ) {
        // Check if more content can be loaded before updating scrolled
        if (
          (pageState + 1) * 10 <=
            (filteredUsersIds && filteredUsersIds.length) &&
          !isLoadingUsers &&
          usersList &&
          usersListData &&
          Object.keys(usersList).length === Object.keys(usersListData).length
        ) {
          setScrolled(prevScrolled => prevScrolled + 1);
        }
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  // end of handle scroll event-------

  const renderFilters = () => {
    return (
      <>
        <div className={styles.regularElement}>
          <CustomersListFiltersRegular
            nameEmailInput={nameEmailInput}
            setPageState={setPageState}
            setNameEmailInput={setNameEmailInput}
            clientPageEventLogger={clientPageEventLogger}
            clearFiltersClicked={clearFiltersClicked}
            allCLientsState={allCLientsState}
            liveToggle={liveToggle}
            setLiveToggle={setLiveToggle}
            archiveToggle={archiveToggle}
            setArchiveToggle={setArchiveToggle}
            nfFlag={nfFlag}
            filtersConfig={filtersConfig}
            doNotHaveUpcomingAssessmentToggle={
              doNotHaveUpcomingAssessmentToggle
            }
            setDoNotHaveUpcomingAssessmentToggle={
              setDoNotHaveUpcomingAssessmentToggle
            }
            completedAssessmentsMin={completedAssessmentsMin}
            completedAssessmentsMax={completedAssessmentsMax}
            setCompletedAssessmentsMin={setCompletedAssessmentsMin}
            setCompletedAssessmentsMax={setCompletedAssessmentsMax}
            filterModalState={filterModalState}
            setFilterModalState={setFilterModalState}
            anchorEl={anchorEl}
            hasRemainingSessionsToggle={hasRemainingSessionsToggle}
            setHasRemainingSessionsToggle={setHasRemainingSessionsToggle}
            completedSessionMin={completedSessionMin}
            completedSessionMax={completedSessionMax}
            setCompletedSessionMin={setCompletedSessionMin}
            setCompletedSessionMax={setCompletedSessionMax}
            hasProgressReportToggle={hasProgressReportToggle}
            setHasProgressReportToggle={setHasProgressReportToggle}
            generatedReportsMin={generatedReportsMin}
            setGeneratedReportsMin={setGeneratedReportsMin}
            generatedReportsMax={generatedReportsMax}
            setGeneratedReportsMax={setGeneratedReportsMax}
            journeyEnableToggle={journeyEnableToggle}
            setJourneyEnableToggle={setJourneyEnableToggle}
            completedJourneyMin={completedJourneyMin}
            setCompletedJourneyMin={setCompletedJourneyMin}
            completedJourneyMax={completedJourneyMax}
            setCompletedJourneyMax={setCompletedJourneyMax}
            actionNeededToggle={actionNeededToggle}
            setActionNeededToggle={setActionNeededToggle}
            pendingJournalsToggle={pendingJournalsToggle}
            setPendingJournalsToggle={setPendingJournalsToggle}
            setAllCLientsState={setAllCLientsState}
            onFilterChange={onFilterChange}
            setArchiveCheckboxes={setArchiveCheckboxes}
            filterModalDisplayClicked={filterModalDisplayClicked}
            clearFiltersRerender={clearFiltersRerender}
            setClearFiltersRerender={setClearFiltersRerender}
            shouldShowTeamMemberAssign={shouldShowTeamMemberAssign}
            setAssignedToggle={setAssignedToggle}
            assignedToggle={assignedToggle}
          />
        </div>
        <div className={styles.mobileElement}>
          <CustomersListFiltersMobile
            nameEmailInput={nameEmailInput}
            setPageState={setPageState}
            setNameEmailInput={setNameEmailInput}
            clientPageEventLogger={clientPageEventLogger}
            clearFiltersClicked={clearFiltersClicked}
            allCLientsState={allCLientsState}
            liveToggle={liveToggle}
            setLiveToggle={setLiveToggle}
            archiveToggle={archiveToggle}
            setArchiveToggle={setArchiveToggle}
            nfFlag={nfFlag}
            filtersConfig={filtersConfig}
            doNotHaveUpcomingAssessmentToggle={
              doNotHaveUpcomingAssessmentToggle
            }
            setDoNotHaveUpcomingAssessmentToggle={
              setDoNotHaveUpcomingAssessmentToggle
            }
            completedAssessmentsMin={completedAssessmentsMin}
            completedAssessmentsMax={completedAssessmentsMax}
            setCompletedAssessmentsMin={setCompletedAssessmentsMin}
            setCompletedAssessmentsMax={setCompletedAssessmentsMax}
            filterModalState={filterModalState}
            setFilterModalState={setFilterModalState}
            anchorEl={anchorEl}
            hasRemainingSessionsToggle={hasRemainingSessionsToggle}
            setHasRemainingSessionsToggle={setHasRemainingSessionsToggle}
            completedSessionMin={completedSessionMin}
            completedSessionMax={completedSessionMax}
            setCompletedSessionMin={setCompletedSessionMin}
            setCompletedSessionMax={setCompletedSessionMax}
            hasProgressReportToggle={hasProgressReportToggle}
            setHasProgressReportToggle={setHasProgressReportToggle}
            generatedReportsMin={generatedReportsMin}
            setGeneratedReportsMin={setGeneratedReportsMin}
            generatedReportsMax={generatedReportsMax}
            setGeneratedReportsMax={setGeneratedReportsMax}
            journeyEnableToggle={journeyEnableToggle}
            setJourneyEnableToggle={setJourneyEnableToggle}
            completedJourneyMin={completedJourneyMin}
            setCompletedJourneyMin={setCompletedJourneyMin}
            completedJourneyMax={completedJourneyMax}
            setCompletedJourneyMax={setCompletedJourneyMax}
            actionNeededToggle={actionNeededToggle}
            setActionNeededToggle={setActionNeededToggle}
            pendingJournalsToggle={pendingJournalsToggle}
            setPendingJournalsToggle={setPendingJournalsToggle}
            setAllCLientsState={setAllCLientsState}
            onFilterChange={onFilterChange}
            setArchiveCheckboxes={setArchiveCheckboxes}
            filterModalDisplayClicked={filterModalDisplayClicked}
            clearFiltersRerender={clearFiltersRerender}
            setClearFiltersRerender={setClearFiltersRerender}
            shouldShowTeamMemberAssign={shouldShowTeamMemberAssign}
            setAssignedToggle={setAssignedToggle}
            assignedToggle={assignedToggle}
          />
        </div>
      </>
    );
  };

  const renderSorting = () => {
    return (
      <div className={styles.sort_info_container}>
        {// eslint-disable-next-line no-nested-ternary
        isLoadingUsers ? (
          <div />
        ) : Object.keys(usersList).length ===
          Object.keys(usersListData).length ? (
          <span>
            {filteredUsersIds.length === usersListArchiveToggle.length ? (
              <div className={styles.info_light_text}>
                {usersListArchiveToggle.length !== 1
                  ? `${usersListArchiveToggle.length} clients`
                  : `${usersListArchiveToggle.length} client`}
              </div>
            ) : (
              <div className={styles.info_dark_text}>
                {filteredUsersIds.length !== 1
                  ? `${filteredUsersIds.length} clients match`
                  : `${filteredUsersIds.length} client match`}
                <span className={styles.info_light_text}>
                  {' '}
                  of {usersListArchiveToggle.length}
                </span>
              </div>
            )}
          </span>
        ) : (
          <span>
            {/* <CircularProgress size={12} color="inherit" /> Loading more clients */}
          </span>
        )}
        <div className={styles.sort_info_btns_container}>
          {archiveToggle ? (
            <TooltipWrapper
              title={
                <TooltipTitle>
                  {archiveToggle
                    ? 'Please select client/s first to be able to unarchive or delete'
                    : 'Please select client/s first to be able to archive'}
                </TooltipTitle>
              }
              disabled={countUsersToArchive()}
            >
              <div
                className={styles.archive_btn}
                onClick={onDeleteUsersClicked}
              >
                Delete
              </div>
            </TooltipWrapper>
          ) : null}
          <TooltipWrapper
            title={
              <TooltipTitle>
                {archiveToggle
                  ? 'Please select client/s first to be able to unarchive or delete'
                  : 'Please select client/s first to be able to archive'}
              </TooltipTitle>
            }
            disabled={countUsersToArchive()}
          >
            <div className={styles.archive_btn} onClick={onArchiveClicked}>
              {archiveToggle ? 'Unarchive' : 'Archive'}
            </div>
          </TooltipWrapper>
          <div className={styles.btn_container}>
            <Button
              variant="text"
              color="primary"
              onClick={onOpenSortMenu}
              style={{
                marginLeft: 10,
                height: '100%',
                fontSize: 12,
              }}
            >
              <span className={styles.btn_text}>Sort by</span>
              <SwapIcon />
            </Button>
          </div>
          <Menu
            anchorEl={menuAnchorElement}
            open={!!menuAnchorElement}
            onClose={() => setMenuAnchorElement(null)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {sortingOptions
              .filter(option => !option.hidden || nfFlag)
              .map(optionObj => (
                <MenuItem
                  key={optionObj.dbKey}
                  onClick={() => {
                    setSortingSelect(optionObj.value);
                    const copy = JSON.parse(JSON.stringify(filtersConfig));
                    copy.sortingType = optionObj.value;
                    setFiltersConfig(copy);
                    clientPageEventLogger.log('sort_by_applied', {
                      sorting_method: optionObj.value,
                      team_member_id: sessionStorage.teamMemberId,
                    });
                    setMenuAnchorElement(null);
                  }}
                  selected={sortingSelect === optionObj.value}
                  style={{ fontSize: 13 }}
                >
                  {optionObj.text}
                </MenuItem>
              ))}
          </Menu>
          {/* <div style={{ marginLeft: 10 }}>
            <CustomSelect
              selectedOption={sortingSelect}
              onChange={e => {
                setSortingSelect(e.target.value);
                const copy = JSON.parse(JSON.stringify(filtersConfig));
                copy.sortingType = e.target.value;
                setFiltersConfig(copy);
                clientPageEventLogger.log('sort_by_applied', {
                  sorting_method: e.target.value,
                  team_member_id: sessionStorage.teamMemberId,
                });
              }}
              allOptions={sortingOptions.filter(
                option => !option.hidden || nfFlag
              )}
              isSmallSelect
            />
          </div> */}
        </div>
      </div>
    );
  };

  // if (isLoadingUsers) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         width: '100%',
  //         marginTop: 20,
  //       }}
  //     >
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div
            onClick={() => {
              // setToggleAddClient(true);
              if (
                sessionStorage.packageType ===
                PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
              ) {
                showNotification(
                  'danger',
                  'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                );
                return;
              }
              setIsRegistrationDialogOpen(true);
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'add_new_client',
                team_member_id: sessionStorage.teamMemberId,
              });
            }}
            className={styles.add_client_btn}
          >
            {isAddClientClickedWhileLoading ? (
              <CircularProgress color="white" size={12} />
            ) : (
              'ADD NEW CLIENT'
            )}
          </div>
          {!isLoadingUsers && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '15px',
              }}
            >
              <CustomEmailMaskingSwitch
                color="primary"
                checked={isNameMasking}
                onChange={e => {
                  const newCheckedValue = e.target.checked;
                  setIsNameMasking(newCheckedValue);

                  if (!isAdminLogin) {
                    setClientNameMasking({ isEnabled: newCheckedValue });
                    clientPageEventLogger.log('mask_names_toggle_clicked', {
                      enabled: newCheckedValue,
                    });
                    /* eslint-disable */
                    const identifyEvent = new amplitude.Identify();
                    identifyEvent.set(
                      'mask_names_toggle_enabled',
                      newCheckedValue
                    );
                    amplitude.identify(identifyEvent);
                    /* eslint-enable */
                  }
                }}
              />
              <span>Mask names</span>
            </div>
          )}
        </div>
        <div>
          <div className={styles.btn_container}>
            <Button
              variant="text"
              color="primary"
              onClick={onOpenMoreMenu}
              style={{
                // backgroundColor: 'white',
                marginLeft: 10,
                height: '100%',
                fontSize: 12,
              }}
            >
              <MoreIcon />
            </Button>
          </div>
          <Menu
            anchorEl={moreMenuAnchorElement}
            open={!!moreMenuAnchorElement}
            onClose={() => setMoreMenuAnchorElement(null)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ fontSize: 13 }}
          >
            <MenuItem onClick={onExportUsersListToCSV} style={{ fontSize: 13 }}>
              Export as CSV
            </MenuItem>
          </Menu>
        </div>
      </div>
      {renderFilters()}
      {renderSorting()}
      <div className={styles.regularElement}>
        <TableTitlesRegular
          shouldShowTeamMemberAssign={shouldShowTeamMemberAssign}
        />
      </div>
      <div className={styles.mobileElement}>
        <TableTitlesMobile />
      </div>
      {// eslint-disable-next-line no-nested-ternary
      isLoadingUsers || isLoadingFBData ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: 100,
          }}
        >
          <CircularProgress />
        </div>
      ) : usersListData ? (
        // eslint-disable-next-line array-callback-return, consistent-return
        filteredUsersIds.map((userId, index) => {
          if (index < (pageState + 1) * 10) {
            return (
              <CustomerCard
                key={userId}
                userId={userId}
                // userData={usersList[userId]}
                userAdditionalData={
                  usersListData[userId] ? usersListData[userId] : null
                }
                archiveCheckboxes={archiveCheckboxes}
                setArchiveCheckboxes={setArchiveCheckboxes}
                onEditPrograms={() => onEditPrograms(userId)}
                search_box_value={nameEmailInput}
                archiveToggle={archiveToggle}
                reachedClientsLimit={reachedClientsLimit}
                setDisplayRichedLimitModal={setDisplayRichedLimitModal}
                setAddClientReachedLimitModal={setAddClientReachedLimitModal}
                nfFlag={nfFlag}
                clinicNonfPlan={clinicNonfPlan}
                canAccessNf={canAccessNf}
                assessmentAutoDeployableConfigs={
                  assessmentAutoDeployableConfigs
                }
                questionnairesAutoDeployableConfigs={
                  questionnairesAutoDeployableConfigs
                }
                isSample={usersListData[userId].isDemo}
                isNameMasking={isNameMasking}
                maskText={maskText}
                customerCardWrapperRef={customerCardWrapperRef}
                teamMembersList={teamMembersList}
                isTeamMembersMenuOpen={teamMembersMenuOpenId === userId}
                setIsTeamMembersMenuOpen={setIsTeamMembersMenuOpen}
              />
            );
          }
        })
      ) : null}
      {(pageState + 1) * 10 > (filteredUsersIds && filteredUsersIds.length) &&
      !isLoadingUsers &&
      usersList &&
      usersListData &&
      Object.keys(usersList).length === Object.keys(usersListData).length ? (
        <div style={{ textAlign: 'center', marginTop: 40, color: '#9F9F9F' }}>
          You have reached the end of the list
        </div>
      ) : null

      // (
      //   <div
      //     // style={{ textAlign: 'center', marginTop: 40, color: '#9F9F9F' }}
      //     className={styles.see_more}
      //     onClick={() => {
      //       setPageState(pageState + 1);
      //     }}
      //   >
      //     See more
      //   </div>
      // )
      }

      {(pageState + 1) * 10 > (filteredUsersIds && filteredUsersIds.length) &&
      !isLoadingUsers &&
      usersList &&
      usersListData &&
      Object.keys(usersList).length !== Object.keys(usersListData).length ? (
        <div style={{ textAlign: 'center', marginTop: 40, color: '#9F9F9F' }}>
          <CircularProgress size={12} color="inherit" /> Loading more clients
        </div>
      ) : null}
      {allQuestionnaires && allTags && assessmentStagesInfo ? (
        <AddClientModal
          isModalOpen={toggleAddClient}
          clinicId={sessionStorage.userId}
          allQuestionnaires={allQuestionnaires}
          allTags={allTags}
          assessmentStagesInfo={assessmentStagesInfo}
          closeModal={() => {
            setToggleAddClient(false);
          }}
          setIsRegistrationDialogOpen={setIsRegistrationDialogOpen}
          nfFlag={!!nfFlag}
          assessmentAutoDeployableConfigs={assessmentAutoDeployableConfigs}
          questionnairesAutoDeployableConfigs={
            questionnairesAutoDeployableConfigs
          }
        />
      ) : null}
      <Dialog
        open={displayArchiveModal}
        onClose={() => {
          setDisplayArchiveModal(false);
        }}
      >
        {archiveToggle ? null : (
          <img
            src={WarningImg}
            width={50}
            height={50}
            style={{ alignSelf: 'center', marginTop: 20 }}
          />
        )}
        <DialogTitle style={{ alignSelf: 'center' }}>
          <div style={{ fontSize: 16, fontWeight: 400 }}>
            {archiveToggle
              ? 'Unarchive clients'
              : `Archiving clients will cancel all of their ${
                  nfFlag ? 'programs and' : ''
                }  assessments`}
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            alignSelf: 'center',
            // minWidth: window.innerWidth > 480 ? null : 400,
          }}
        >
          {archiveToggle ? (
            <div style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}>
              You’ll be able to deploy {nfFlag ? 'programs and ' : ''}{' '}
              assessments for these clients and they will have full access to
              the Myndlift app.
            </div>
          ) : (
            <div
              style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}
              className={styles.archive_warning_details}
            >
              Archived clients can still access the basic Myndlift app with
              valuable mental wellness articles, journals and meditations. You
              can unarchive the client at anytime to access their profile and
              data.
            </div>
          )}
        </DialogContent>
        <ModalFooter
          primaryBtnContent={archiveToggle ? 'unarchive now' : 'archive now'}
          secondaryBtnContent="cancel"
          onPrimaryBtnClick={onArchiveConfirmed}
          onSecondaryBtnClick={() => {
            setDisplayArchiveModal(false);
          }}
        />
      </Dialog>
      <Dialog
        open={displayRichedLimitModal}
        onClose={() => {
          setDisplayRichedLimitModal(false);
        }}
      >
        {archiveToggle ? (
          <img
            src={WarningImg}
            width={50}
            height={50}
            style={{ alignSelf: 'center', marginTop: 20 }}
          />
        ) : null}
        <DialogTitle style={{ alignSelf: 'center' }}>
          <div style={{ fontSize: 16, fontWeight: 900 }}>
            Your current plan supports up to {usersIncluded} clients only
          </div>
        </DialogTitle>
        <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
          <div style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}>
            {archiveToggle ? (
              'Upgrade your plan to unarchive this client and see their page, deploy programs, assessments, and more.'
            ) : (
              <div>
                <div>
                  Self-registered clients get immediate access to the basic
                  version of the Myndlift app with mental wellness journals,
                  meditations, and articles.
                </div>
                <div style={{ marginTop: 20 }}>
                  To grant them full access to the app with programs,
                  assessments and more, <strong>upgrade your plan now</strong>.
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <ModalFooter
          primaryBtnContent="view pricing plans"
          secondaryBtnContent="cancel"
          onPrimaryBtnClick={async () => {
            onViewPricingPlansClicked();
          }}
          onSecondaryBtnClick={() => {
            setDisplayRichedLimitModal(false);
          }}
        />
      </Dialog>
      <NeuroPromoDialog
        addClientReachedLimitModal={addClientReachedLimitModal}
        setAddClientReachedLimitModal={setAddClientReachedLimitModal}
        canAccessNf={canAccessNf}
        onViewPricingPlansClicked={onViewPricingPlansClicked}
        fromWhere="clients_page"
      />
      <CreateProgramPopUp
        // eslint-disable-next-line no-undef
        protocols={protocolsObjectToArrWithNamesInjected(protocolsConfig)}
        database={database}
        // eslint-disable-next-line no-undef
        modifyProtocol={modifyProtocol}
        // eslint-disable-next-line no-undef
        getOverAllSuccessRate={getOverAllSuccessRate}
        // eslint-disable-next-line no-undef
        searchProtocol={searchProtocol}
        // eslint-disable-next-line no-undef
        PatientProtocolDetails={ProtocolDetails}
        getRecommendedPlacementNameBasedOnPlacement={
          // eslint-disable-next-line no-undef
          getRecommendedPlacementNameBasedOnPlacement
        }
        // eslint-disable-next-line no-undef
        getChosenPlacement={getChosenPlacement}
        // eslint-disable-next-line no-undef
        getChoosenPlacementDescriptionHTML={
          // eslint-disable-next-line no-undef
          getChoosenPlacementDescriptionHTML
        }
        // eslint-disable-next-line no-undef
        placements={placements}
        extractFrequencyName={(min, max) =>
          extractFrequency(min, max, frequenciesConfig)
        }
        clinicId={sessionStorage.userId}
        patientId={selectedUserProgram}
        isModalOpen={!!createProgramPopUpOpener}
        closeModal={closeCreateProgramPopUpModal}
        isEditingMode={
          createProgramPopUpOpener && createProgramPopUpOpener.isEditingMode
        }
        isProgramSettingsAdvancedMode={isCreateProgramPopUpInAdvancedMode}
        onProgramSettingsAdvancedModeChange={
          onProgramSettingsAdvancedModeChange
        }
      />
      <SelfRegistrationDialog
        isOpen={isRegistrationDialogOpen}
        onClose={() => {
          setIsRegistrationDialogOpen(false);
        }}
        registrationCode={clinicUserInfo && clinicUserInfo.registrationCode}
        onAddClientClicked={() => {
          if (isLoadingAddClientData) {
            setIsAddClientClickedWhileLoading(true);
          }
          setToggleAddClient(true);
        }}
        reachedClientsLimit={reachedClientsLimit}
        usersIncluded={usersIncluded}
        onViewPricingPlansClicked={onViewPricingPlansClicked}
        clinicNonfPlan={clinicNonfPlan}
      />
    </div>
  );
};

CustomersListBase.propTypes = exact({});

export const CustomersList = React.memo(CustomersListBase);
CustomersList.displayName = 'CustomersList';
