import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import styles from './SymptomsTrackingGraph.scss';
import { DeepEqualityComponent } from '../../../Core/DeepEqualityComponent/DeepEqualityComponent';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { predefinedColors, graphHeight } from '../constants';
import { generateColors } from '../utils';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { getQuestionnaireLabel } from '../../../models/symptom-tracker/symptom-tracker';
import { xsMediaMaxWidth } from '../../../cssInJs/constants';

class SymptomsTrackingGraphBase extends DeepEqualityComponent {
  render() {
    const {
      legendItems,
      isInPreview,
      selectedQuestionnaires,
      allQuestionnaires,
      onSelectedQuestionnairesChange,
      shouldUseNewSymptomTracker,
      classes,
    } = this.props;
    const data = [];

    this.props.data.forEach(answersSet => {
      const dataSet = {};
      dataSet.date = answersSet.date;
      answersSet.questions.forEach(question => {
        if (
          legendItems.find(
            legendItemArr => legendItemArr[0] === question.name
          )?.[1]
        ) {
          dataSet[question.name] = question.rating;
        }
      });
      data.push(dataSet);
    });

    const numOfLegendItems = Object.keys(legendItems).length;

    const colors =
      numOfLegendItems > predefinedColors.length
        ? generateColors(numOfLegendItems)
        : predefinedColors;

    const isMatchingXSScreen = window.matchMedia(
      `(max-width: ${xsMediaMaxWidth}px)`
    ).matches;

    const yAxisLabelProps = isMatchingXSScreen
      ? {
          value: 'Score',
          position: 'top',
        }
      : {
          value: 'Score',
          angle: -90,
          position: 'center',
        };

    return (
      <div>
        {!isInPreview && shouldUseNewSymptomTracker && (
          <div
            className={
              classes.questionnaires_select
                ? classes.questionnaires_select
                : styles.questionnaires_select
            }
          >
            <CheckboxesSelect
              inputLabelText="Questionnaires"
              selectedOptions={selectedQuestionnaires}
              allOptions={Object.values(allQuestionnaires).map(
                questionnaire => ({
                  label: getQuestionnaireLabel(questionnaire),
                  value: questionnaire.id,
                })
              )}
              onChange={onSelectedQuestionnairesChange}
              renderValue={questionnairesIds => {
                return questionnairesIds.length > 1 &&
                  new Set(
                    questionnairesIds.map(
                      id => allQuestionnaires[id].originalIdWithoutSubScale
                    )
                  ).size > 1
                  ? 'Multiple questionnaires'
                  : getQuestionnaireLabel(
                      allQuestionnaires[questionnairesIds[0]],
                      {
                        shouldUseSubScale: questionnairesIds.length === 1,
                      }
                    );
              }}
              fullWidth
            />
          </div>
        )}
        <div>
          <ResponsiveContainer width="100%" height={graphHeight}>
            <AreaChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid horizontal={false} />
              <XAxis dataKey="date" tickMargin={7} tick={{ fontSize: 12 }} />
              <YAxis
                width={100}
                tickMargin={7}
                tick={{ fontSize: 12 }}
                label={yAxisLabelProps}
              />
              <Tooltip
                contentStyle={{ overflow: 'hidden', whiteSpace: 'unset' }}
                position={{ x: 0, y: 0 }}
              />

              {data[0] &&
                legendItems.map(([dataKey, isLegendEnabled], index) => (
                  <Area
                    type="monotone"
                    dataKey={dataKey}
                    key={dataKey}
                    hide={!isLegendEnabled}
                    stackId="1"
                    stroke={chroma(colors[index])
                      .darken(2)
                      .saturate(2)
                      .hex()}
                    fill={colors[index]}
                  />
                ))}
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className={styles.legend_container}>
          {// We can't use ClickableLegendItems here because we have to
          // represent legendItems as array
          // because some of them contain chars that are forbidden in firebase
          // and can't be used as keys then
          legendItems.map(([dataKey, isLegendEnabled], index) => (
            <div
              key={dataKey}
              className={styles.legend}
              style={{
                display: isInPreview && !isLegendEnabled ? 'none' : '',
              }}
            >
              <div
                className={styles.legend_circle}
                style={{
                  background: isLegendEnabled ? colors[index] : 'black',
                  cursor: isInPreview ? 'default' : 'pointer',
                }}
                onClick={() => this.props.onLegendItemClick(dataKey)}
              />
              <div
                className={styles.legend_name}
                onClick={() => this.props.onLegendItemClick(dataKey)}
                style={{
                  cursor: isInPreview ? 'default' : 'pointer',
                }}
              >
                <p>{dataKey}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

SymptomsTrackingGraphBase.defaultProps = {
  isInPreview: false,
  onLegendItemClick: () => {},
  classes: {},
};

SymptomsTrackingGraphBase.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          rating: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onLegendItemClick: PropTypes.func,
  legendItems: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired,
  isInPreview: PropTypes.bool,
  classes: PropTypes.object,
};

export const SymptomsTrackingGraph = SymptomsTrackingGraphBase;
