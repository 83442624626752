import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import omit from 'lodash/omit';
import EdiText from 'react-editext';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  Paper,
  TextField,
  MenuItem,
  IconButton,
  Switch,
} from '@material-ui/core';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import styles from './QuestionnaireDialog.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { isQuestionDeletableKey } from '../manageQuestionnairesPageUtils';

const EditTextWrapper = ({ errorMsg, ...props }) => (
  <div className={styles.edit_text_wrapper}>
    <EdiText
      saveButtonContent={<CheckIcon style={{ color: '#2196f3' }} />}
      editButtonContent={
        <EditIcon style={{ fontSize: 17, color: '#2196f3' }} />
      }
      cancelButtonContent={<CloseIcon style={{ color: '#2196f3' }} />}
      saveButtonClassName={styles.edit_text_button}
      editButtonClassName={styles.edit_text_button}
      cancelButtonClassName={styles.edit_text_button}
      editOnViewClick
      hideIcons
      submitOnEnter
      submitOnUnfocus
      cancelOnEscape
      startEditingOnFocus
      tabIndex={0}
      {...props}
    />
    {errorMsg}
  </div>
);

EditTextWrapper.propTypes = {
  errorMsg: PropTypes.node,
};

export const QUESTIONNAIRE_DIALOG_RENDERED_STEPS = {
  FORM: 'form',
  SUCCESS: 'success',
  LOADING: 'loading',
};

const QuestionnaireDialogBase = ({
  questionnaire,
  isOpen,
  onPrimaryBtnClick,
  onCancel,
  onClose,
  onEditQuestionnaire,
  onAddNewQuestion,
  renderedStep,
  selectedAnswersScale,
  onSelectedAnswersScaleChange,
  answerScalesOptions,
  validateQuestionnaireName,
  questionnaireNameErrorMsg,
  validateQuestionnaireInstruction,
  questionnaireInstructionErrorMsg,
  isPrimaryBtnDisabled,
  toggleDiffrentScale,
  setToggleDiffrentScale,
  scalePerQuestion,
}) => {
  const t = useTranslation();
  const [hiddenDeletedButtons, setHiddenDeletedButtons] = useState({});

  const renderForm = () => (
    <>
      <div className={styles.questionnaire_name}>
        <EditTextWrapper
          type="text"
          value={questionnaire.name}
          onSave={value =>
            onEditQuestionnaire({ ...questionnaire, name: value })
          }
          viewContainerClassName={styles.edit_text_container_questionnaire_name}
          validation={validateQuestionnaireName}
          onCancel={validateQuestionnaireName}
          validationMessage={null}
          errorMsg={
            questionnaireNameErrorMsg && (
              <div className={styles.edit_text_validation_msg}>
                {questionnaireNameErrorMsg}
              </div>
            )
          }
          // the following is a hack because edit text component is not exposing an api to make it a fully controlled component
          editing={!!questionnaireNameErrorMsg}
        />
      </div>

      <div className={styles.questionnaire_instruction}>
        <EditTextWrapper
          type="text"
          value={questionnaire.instruction}
          onSave={value =>
            onEditQuestionnaire({ ...questionnaire, instruction: value })
          }
          viewContainerClassName={
            styles.edit_text_container_questionnaire_instruction
          }
          validation={validateQuestionnaireInstruction}
          onCancel={validateQuestionnaireInstruction}
          validationMessage={null}
          errorMsg={
            questionnaireInstructionErrorMsg && (
              <div className={styles.edit_text_validation_msg}>
                {questionnaireInstructionErrorMsg}
              </div>
            )
          }
          editing={!!questionnaireInstructionErrorMsg}
        />
      </div>

      <div>
        <div className={styles.questions_container}>
          {questionnaire.questions.map((question, index) => (
            <Paper key={question.questionId}>
              <div className={styles.question_container}>
                <EditTextWrapper
                  type="text"
                  value={question.title.en}
                  onSave={value => {
                    // eslint-disable-next-line no-param-reassign
                    questionnaire.questions[index].title.en = value;
                    onEditQuestionnaire(questionnaire);
                    setHiddenDeletedButtons(
                      omit(hiddenDeletedButtons, [question.questionId])
                    );
                  }}
                  validation={q =>
                    q.trim().length <= 200 && q.trim().length > 0
                  }
                  validationMessage={
                    <div className={styles.edit_text_validation_msg}>
                      {t('question-less-than-200-chars')}
                    </div>
                  }
                  viewContainerClassName={styles.edit_text_container_question}
                  onEditingStart={() => {
                    setHiddenDeletedButtons({
                      ...hiddenDeletedButtons,
                      [question.questionId]: true,
                    });
                  }}
                  onCancel={() => {
                    setHiddenDeletedButtons(
                      omit(hiddenDeletedButtons, [question.questionId])
                    );
                  }}
                />
                <div
                  className={styles.delete_icon_container}
                  style={{
                    display:
                      !question[isQuestionDeletableKey] ||
                      hiddenDeletedButtons[question.questionId]
                        ? 'none'
                        : 'revert',
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={() => {
                      questionnaire.questions.splice(index, 1);
                      onEditQuestionnaire(questionnaire);
                    }}
                  >
                    <DeleteIcon style={{ fontSize: 20 }} />
                  </IconButton>
                </div>
                {toggleDiffrentScale ? (
                  <div
                    className={styles.response_scale_container}
                    style={{ marginTop: 10 }}
                  >
                    <TextField
                      select
                      label="Response Scale"
                      value={
                        scalePerQuestion[question.questionId] ||
                        selectedAnswersScale
                      }
                      onChange={event =>
                        onSelectedAnswersScaleChange(
                          event.target.value,
                          question.questionId
                        )
                      }
                      classes={{
                        root: styles.select_text_field_root,
                      }}
                      InputProps={{
                        classes: {
                          root: styles.select_input_root,
                        },
                      }}
                    >
                      {answerScalesOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <span className={styles.option}>{option.label}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                ) : null}
              </div>
            </Paper>
          ))}

          <Button variant="text" color="primary" onClick={onAddNewQuestion}>
            <AddIcon />{' '}
            <span className={styles.btn_text}>{t('add-new-question')}</span>
          </Button>
        </div>

        <div className={styles.response_scale_container}>
          <TextField
            select
            label="Response Scale"
            value={selectedAnswersScale}
            onChange={event => onSelectedAnswersScaleChange(event.target.value)}
            classes={{
              root: styles.select_text_field_root,
            }}
            InputProps={{
              classes: {
                root: styles.select_input_root,
              },
            }}
            disabled={toggleDiffrentScale}
          >
            {answerScalesOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                <span className={styles.option}>{option.label}</span>
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          Different response scale for each question
          <Switch
            checked={toggleDiffrentScale}
            onChange={() => {
              setToggleDiffrentScale(!toggleDiffrentScale);
            }}
            color="primary"
          />
        </div>
      </div>
    </>
  );

  const renderSuccess = () => {
    return (
      <div>
        <p>{t('questionnaire-added-edited')}</p>
      </div>
    );
  };

  const renderLoader = () => {
    return <Loader />;
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={onCancel}
      onClose={onClose}
      primaryBtnContent={
        renderedStep === QUESTIONNAIRE_DIALOG_RENDERED_STEPS.SUCCESS
          ? 'done'
          : 'save'
      }
      secondaryBtnContent={
        renderedStep === QUESTIONNAIRE_DIALOG_RENDERED_STEPS.SUCCESS
          ? null
          : 'cancel'
      }
      customClasses={{
        dialog_paper: styles.dialog_paper,
      }}
      shouldShowButtons={
        renderedStep !== QUESTIONNAIRE_DIALOG_RENDERED_STEPS.LOADING
      }
      isPrimaryBtnDisabled={isPrimaryBtnDisabled}
      disableBackdropClick
    >
      {questionnaire && (
        <>
          {renderedStep === QUESTIONNAIRE_DIALOG_RENDERED_STEPS.FORM &&
            renderForm()}
          {renderedStep === QUESTIONNAIRE_DIALOG_RENDERED_STEPS.SUCCESS &&
            renderSuccess()}
          {renderedStep === QUESTIONNAIRE_DIALOG_RENDERED_STEPS.LOADING &&
            renderLoader()}
        </>
      )}
    </CustomDialog>
  );
};

QuestionnaireDialogBase.defaultProps = {
  questionnaire: null,
};

QuestionnaireDialogBase.propTypes = exact({
  questionnaire: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onEditQuestionnaire: PropTypes.func.isRequired,
  onAddNewQuestion: PropTypes.func.isRequired,
  renderedStep: PropTypes.string.isRequired,
  selectedAnswersScale: PropTypes.string.isRequired,
  onSelectedAnswersScaleChange: PropTypes.func.isRequired,
  answerScalesOptions: PropTypes.array.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  validateQuestionnaireName: PropTypes.func.isRequired,
  questionnaireNameErrorMsg: PropTypes.string,
  validateQuestionnaireInstruction: PropTypes.func.isRequired,
  questionnaireInstructionErrorMsg: PropTypes.string,
  isPrimaryBtnDisabled: PropTypes.bool.isRequired,
  toggleDiffrentScale: PropTypes.bool.isRequired,
  setToggleDiffrentScale: PropTypes.func.isRequired,
  scalePerQuestion: PropTypes.object,
});

export const QuestionnaireDialog = React.memo(QuestionnaireDialogBase);
QuestionnaireDialog.displayName = 'QuestionnaireDialog';
