import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import BlueMailIcon from '../mail.png';
import RedMailIcon from '../red-mail-icon.png';
import YellowMailIcon from '../yellow-mail-icon.png';
import FlagIcon from '../flag.png';
import InfoNIcon from '../info.png';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import styles from '../CustomersList.scss';

const TableTitlesRegularBase = ({ shouldShowTeamMemberAssign }) => {
  return (
    <div className={styles.table_titles_container}>
      <div
        className={styles.table_titles_container_item}
        style={
          shouldShowTeamMemberAssign
            ? { justifyContent: 'center', width: '13%' }
            : { justifyContent: 'center' }
        }
      >
        {/* <PersonIcon /> */}
        <div style={{ position: 'relative', right: 30 }}>Client</div>
      </div>
      <div
        className={styles.table_titles_container_item}
        style={shouldShowTeamMemberAssign ? { width: '13%' } : null}
      >
        {/* <DateRangeIcon /> */}
        <div>Signed up</div>
      </div>
      <div
        className={styles.table_titles_container_item}
        style={shouldShowTeamMemberAssign ? { width: '13%' } : null}
      >
        {/* <DateRangeIcon /> */}
        <div>Last seen</div>
      </div>
      <div
        className={styles.table_titles_container_item}
        style={shouldShowTeamMemberAssign ? { width: '13%' } : null}
      >
        {/* <NotificationsIcon /> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Notifications
          <TooltipWrapper
            title={
              <TooltipTitle>
                <div style={{ fontWeight: 'bold', margin: '10px 0' }}>
                  Client Entries
                </div>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={RedMailIcon}
                    width={15}
                    height={15}
                    style={{ marginRight: 5 }}
                  />{' '}
                  Client asked for feedback
                </div>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={YellowMailIcon}
                    width={15}
                    height={15}
                    style={{ marginRight: 5 }}
                  />{' '}
                  Client replied to feedback
                </div>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={BlueMailIcon}
                    width={15}
                    height={15}
                    style={{ marginRight: 5 }}
                  />{' '}
                  Client shared an entry
                </div>
                <div style={{ fontWeight: 'bold', margin: '10px 0' }}>
                  Action Needed
                </div>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={FlagIcon}
                    width={15}
                    height={15}
                    style={{ marginRight: 5 }}
                  />{' '}
                  Updates
                </div>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={InfoNIcon}
                    width={15}
                    height={15}
                    style={{ marginRight: 5 }}
                  />{' '}
                  To-do
                </div>
              </TooltipTitle>
            }
          >
            <span style={{ position: 'relative', top: 3, left: 5 }}>
              <InfoIcon />
            </span>
          </TooltipWrapper>
        </div>
      </div>
      <div
        className={styles.table_titles_container_item}
        style={shouldShowTeamMemberAssign ? { width: '13%' } : null}
      >
        {/* <DateRangeIcon /> */}
        <div>Assessment</div>
      </div>
      <div
        className={styles.table_titles_container_item}
        style={shouldShowTeamMemberAssign ? { width: '13%' } : null}
      >
        {/* <DateRangeIcon /> */}
        <div>Training</div>
      </div>
      {shouldShowTeamMemberAssign ? (
        <div
          className={styles.table_titles_container_item}
          style={shouldShowTeamMemberAssign ? { width: '13%' } : null}
        >
          {/* <DateRangeIcon /> */}
          <div>Assigned</div>
        </div>
      ) : null}
    </div>
  );
};

TableTitlesRegularBase.propTypes = exact({
  shouldShowTeamMemberAssign: PropTypes.bool,
});

export const TableTitlesRegular = React.memo(TableTitlesRegularBase);
TableTitlesRegular.displayName = 'TableTitlesRegular';
