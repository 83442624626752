import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import styles from './AssessmentQuestionnaireResults.scss';
import { QuestionnaireResults } from '../../QuestionnaireResults/QuestionnaireResults';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';

const AssessmentQuestionnaireResultsBase = ({
  isInPreview,
  isSomeDataHidden,
  preAssessment,
  postAssessment,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  questionnaires,
  questionnaireResults,
  postQuestionnaireResults,
  allQuestionnairesOptions,
  selectedQuestionnaires,
  onSelectedQuestionnairesChange,
  allQuestionnaires,
}) => {
  const filteredQuestionnariesIds = Object.keys(questionnaires).filter(
    questionnaireId => !questionnaires[questionnaireId].hideResult
  );
  const filteredQuestionnaries = {};
  filteredQuestionnariesIds.forEach(Id => {
    filteredQuestionnaries[Id] = questionnaires[Id];
  });

  return (
    <AssessmentComparisonGraph
      hiddenDataMsg="Some data is not showing since the assessments being compared did not have matching questionnaires."
      {...{
        isInPreview,
        isSomeDataHidden,
        preAssessment,
        postAssessment,
        onPreAssessmentChange,
        onPostAssessmentChange,
        assessmentOptions,
        preAssessmentTimestamp,
        postAssessmentTimestamp,
      }}
    >
      <div>
        {!isInPreview && !!allQuestionnairesOptions.length && (
          <div className={styles.questionnaires_select}>
            <CheckboxesSelect
              selectedOptions={selectedQuestionnaires}
              allOptions={allQuestionnairesOptions}
              onChange={onSelectedQuestionnairesChange}
              renderValue={() =>
                selectedQuestionnaires
                  .map(
                    questionnaireId =>
                      filteredQuestionnaries[questionnaireId].name
                  )
                  .join(', ')
              }
              inputLabelText="Questionnaires"
            />
          </div>
        )}
        <div>
          <QuestionnaireResults
            questionnaires={questionnaires}
            questionnaireResults={questionnaireResults}
            postQuestionnaireResults={postQuestionnaireResults}
            classes={{
              checkGraph: styles.check_graph,
              checkContainer: styles.check_container,
            }}
            allQuestionnaires={allQuestionnaires}
          />
        </div>
      </div>
    </AssessmentComparisonGraph>
  );
};

AssessmentQuestionnaireResultsBase.propTypes = exact({
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  isSomeDataHidden: PropTypes.bool.isRequired,
  questionnaires: PropTypes.object.isRequired,
  allQuestionnairesOptions: PropTypes.array.isRequired,
  questionnaireResults: PropTypes.object.isRequired,
  postQuestionnaireResults: PropTypes.object.isRequired,
  selectedQuestionnaires: PropTypes.array.isRequired,
  onSelectedQuestionnairesChange: PropTypes.func.isRequired,
  allQuestionnaires: PropTypes.object.isRequired,
});

export const AssessmentQuestionnaireResults = React.memo(
  AssessmentQuestionnaireResultsBase
);
AssessmentQuestionnaireResults.displayName = 'AssessmentQuestionnaireResults';
